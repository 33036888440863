import React, { useState } from 'react';
import { Button, Input, Row, Col, message, Modal, Spin, Card, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import {  useGetSLAMutation, useGetServicesQuery } from '../../../../store/RTK_query/service.api.query'; // Ensure the correct path
import DOMPurify from 'dompurify'; // Ensure DOMPurify is installed

const ChooseServicesStep = ({
    suscribedServiceIds,
    setSuscribedServiceIds,
    removedServiceIds,
    setRemovedServiceIds,
    newAcceptedServiceIds,
    setNewAcceptedServiceIds
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const services = useGetServicesQuery("ACTIVE_ALL");
    const [getSLA, SLARes] = useGetSLAMutation();

    const servicesData = services?.data?.response || [];
    const handleServiceSelect = (serviceId, serviceName) => {
        // Show loading spinner while fetching SLA
        const modal = Modal.info({
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ marginBottom: '16px' }}>
                        {serviceName + " (SLA)"}
                    </div>
                    <Button
                        type="text"
                        onClick={() => modal.destroy()}
                        style={{ color: '#aaa' }}
                    >
                        &times;
                    </Button>
                </div>
            ),
            content: (
                <div style={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Spin spinning={true} size="large" style={{ marginBottom: '20px' }} />
                    <div style={{ display: 'none' }}>
                        {/* Placeholder for the actual content until it's loaded */}
                    </div>
                </div>
            ),
            okText: 'Close',
            width: '90%',
            style: { maxWidth: '800px' },
        });

        // Fetching SLA
        getSLA(serviceId).then(res => {
            if (res?.data?.status === 0) {
                const sanitizedHTML = DOMPurify.sanitize(res?.data?.response?.agreement);
                modal.update({
                    content: (
                        <div style={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column' }}>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    maxHeight: '60vh',
                                    marginBottom: '20px',
                                    padding: '10px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    backgroundColor: '#ffffff'
                                }}
                                dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                            />
                        </div>
                    ),
                    footer: (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button
                                onClick={() => {
                                    handleAcceptService(serviceId);
                                    modal.destroy();
                                }}
                            >
                                Accept Agreement
                            </Button>
                            <Button onClick={() => modal.destroy()}>
                                Not Accept
                            </Button>
                        </div>
                    ),
                });
            }
        }).catch(error => {
            console.error("Error fetching SLA:", error);
            message.error("Failed to load Service Level Agreement!");
            modal.destroy();
        });
    };

    const handleAcceptService = (serviceId) => {
        if (!suscribedServiceIds.includes(serviceId)) {
            setSuscribedServiceIds([...suscribedServiceIds, serviceId]);
            setNewAcceptedServiceIds([...newAcceptedServiceIds, serviceId]);
            message.success('Service added!');
        }
    };

    const handleRemoveService = (serviceId) => {
        setSuscribedServiceIds(suscribedServiceIds.filter((id) => id !== serviceId));
        setRemovedServiceIds([...removedServiceIds, serviceId]);
        message.error('Service removed!');
    };

    return (
        <>
            <Input.Search placeholder="Search for services" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            <Row gutter={[16, 16]}>
                {servicesData?.filter((service) => service.serviceName.toLowerCase().includes(searchTerm.toLowerCase())).map((service) => (
                    <Col key={service.serviceId} span={8} sm={24} md={8} >
                        <Card
                            style={{
                                margin: '10px',
                                padding: '5px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                                height: "100px",
                                backgroundColor: suscribedServiceIds.includes(service.serviceId) ? '#f6ffed' : '#fff', // Light green for subscribed services
                                border: suscribedServiceIds.includes(service.serviceId) ? '1px solid #b7eb8f' : '1px solid #d9d9d9', // Border color changes based on subscription
                            }}
                            onClick={() => handleServiceSelect(service.serviceId, service.serviceName)}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {suscribedServiceIds.includes(service.serviceId) && (
                                    <CheckCircleFilled style={{ color: '#52c41a', marginRight: '8px' }} />
                                )}
                                <Typography.Text style={{ fontSize: '16px', fontWeight: '500' }}>
                                    {service.serviceName}
                                </Typography.Text>
                            </div>
                            {suscribedServiceIds.includes(service.serviceId) && (
                                <Button
                                    type="link"
                                    danger
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the Card click
                                        handleRemoveService(service.serviceId);
                                    }}
                                    style={{ padding: 0 }} // Remove default padding for a cleaner look
                                >
                                    Unsubscribe
                                </Button>
                            )}
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default ChooseServicesStep;
