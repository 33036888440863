import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Typography,
    notification,
    Spin,
    Upload,
    message,
    Divider,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import {
    useGetAgentDetailsQuery,
    useGetAgentDocumentsSelfMutation,
    useUpdateAgentProfileMutation,
} from '../../../store/RTK_query/agent.api.query';
import AgentMapComponent from '../../../components/AgentMapComponent';
import { UploadOutlined } from '@ant-design/icons';

export default function AgentProfile() {
    const { data: agentData, isLoading } = useGetAgentDetailsQuery();
    const updateAgent = agentData?.response;
    const [form] = Form.useForm();
    const [updateProfile] = useUpdateAgentProfileMutation();
    const [locationData, setLocationData] = useState(null);
    const [isAddressVerified, setIsAddressVerified] = useState(false);
    const [agentDetails, setAgentDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
    });
    const [address, setAddress] = useState('');
    const [profilePicture, setProfilePicture] = useState(null); // State for profile picture
    const [licenseFile, setLicenseFile] = useState(null); // State for license file
    const [licenseFileUrl, setLicenseFileUrl] = useState(null);
    const [profileFileUrl, setProfileFileUrl] = useState(null);
    const [licenseFileName, setLicenseFileName] = useState(null);
    const [profileFileName, setProfileFileName] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false); // For modal visibility
    const [previewType, setPreviewType] = useState(null); // To differentiate preview type
    const [getAgentDocuments, agentDocumentsRes] = useGetAgentDocumentsSelfMutation();

    const handleGetDocuments = async () => {
        try {
            const response = await getAgentDocuments().unwrap();
            const licenseBlob = new Blob([response.license], { type: 'application/pdf' });
            const profileBlob = new Blob([response.profile], { type: 'image/jpeg' });

            setLicenseFileUrl(URL.createObjectURL(licenseBlob));
            setLicenseFileName('license.pdf');
            setProfileFileUrl(URL.createObjectURL(profileBlob));
            setProfileFileName('profile.jpg');
        } catch (error) {
            message.error('Error retrieving files');
        }
    };

    useEffect(() => {
        if (updateAgent) {
            handleGetDocuments();
            form.setFieldsValue({
                firstName: updateAgent.firstName,
                lastName: updateAgent.lastName,
                email: updateAgent.email,
                contactNumber: updateAgent.contactNumber,
            });
            setAddress(updateAgent.address || '');
            setAgentDetails({
                firstName: updateAgent.firstName,
                lastName: updateAgent.lastName,
                email: updateAgent.email,
                contactNumber: updateAgent.contactNumber,
            });
        }
    }, [updateAgent, form]);

    const handleVerifyAddress = () => {
        if (locationData?.formattedAddress && locationData?.lat && locationData?.lng) {
            setIsAddressVerified(true);
            Modal.success({
                title: 'Address Verified',
                content: (
                    <div>
                        <p>
                            <b>Address :</b> {locationData?.formattedAddress}
                        </p>
                        <p>
                            <b>Latitude:</b> {locationData?.lat}
                        </p>
                        <p>
                            <b>Longitude:</b> {locationData?.lng}
                        </p>
                        <p>
                            <b>City:</b> {locationData?.city}
                        </p>
                        <p>
                            <b>State:</b> {locationData?.state}
                        </p>
                        <p>
                            <b>Country:</b> {locationData?.country}
                        </p>
                        <p>
                            The address and location details are verified successfully. Please click "Next" to proceed.
                        </p>
                    </div>
                ),
            });
        } else {
            setIsAddressVerified(false);
            Modal.error({ title: 'Verification Failed', content: 'Invalid address or location data.' });
        }
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
        setIsAddressVerified(false);
    };

    const handleLocationChange = (location) => {
        setLocationData(location);
        setIsAddressVerified(false);
    };

    const handleSubmit = () => {
        if (!locationData) {
            notification.error({
                message: 'Address Not Verified',
                description: 'Please verify your address before submitting the form.',
            });
            return;
        }

        const details = {
            ...agentDetails,
            address: locationData?.formattedAddress,
            latitude: locationData?.lat,
            longitude: locationData?.lng,
            city: locationData?.city,
            state: locationData?.state,
            country: locationData?.country,
            removedDocServiceIds: [],
            newAcceptedServiceIds: [],
            agentId: updateAgent?.agentId,
            profilePicture: profilePicture,
            licenseFile: licenseFile,
        };

        const formData = new FormData();
        if (licenseFile) {
            formData.append('citizenServiceCenterLicense', licenseFile);
        }

        if (profilePicture) {
            formData.append('agentProfilePicture', profilePicture);
        }

        formData.append('infoDto', new Blob([JSON.stringify(details)], { type: 'application/json' }));

        updateProfile(formData).then((res) => {
            if (res?.data?.status === 0) {
                notification.success({
                    message: 'Profile Update',
                    description: 'Profile details updated successfully!',
                });
            } else {
                notification.error({
                    message: 'Profile Not Updated',
                    description: 'Something went wrong, please try later.',
                });
            }
        });
    };

    if (isLoading) {
        return <Spin size="large" style={{ display: 'block', margin: 'auto', padding: '100px' }} />;
    }

    return (
        <Content style={{ padding: '16px' }}>
            <Row justify="center">
                <Col xs={24} md={12}>
                    <Card bordered={false}>
                        <Typography.Title level={2} style={{ textAlign: 'center' }}>
                            Update Profile
                        </Typography.Title>

                        <Card title="Basic Details" style={{ marginBottom: '20px' }}>
                            <Form form={form} layout="vertical" autoComplete="off">
                                <Row gutter={16} justify="center">
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="First Name" required>
                                            <Input
                                                name="firstName"
                                                placeholder="Enter first name"
                                                value={agentDetails.firstName}
                                                onChange={(e) =>
                                                    setAgentDetails({ ...agentDetails, firstName: e.target.value })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Last Name">
                                            <Input
                                                name="lastName"
                                                placeholder="Enter last name"
                                                value={agentDetails.lastName}
                                                onChange={(e) =>
                                                    setAgentDetails({ ...agentDetails, lastName: e.target.value })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Email" required>
                                            <Input
                                                name="email"
                                                readOnly
                                                placeholder="Enter email"
                                                value={agentDetails.email}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Contact Number" required>
                                            <Input
                                                name="contactNumber"
                                                placeholder="Enter contact number"
                                                value={agentDetails.contactNumber}
                                                onChange={(e) =>
                                                    setAgentDetails({ ...agentDetails, contactNumber: e.target.value })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        {/* Updated Upload Documents Section */}
                        <Card title="Upload Documents" style={{ marginBottom: '20px' }}>
                            <label>Profile Picture</label>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {/* Display profile picture */}
                                    {profileFileUrl ? (
                                        <img
                                            src={profileFileUrl}
                                            alt="Profile"
                                            style={{
                                                width: '120px',
                                                height: '120px',
                                                marginTop: '10px',
                                                borderRadius: '50%',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                width: '120px',
                                                height: '120px',
                                                marginTop: '10px',
                                                borderRadius: '50%',
                                                backgroundColor: '#f0f0f0',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                            }}
                                        >
                                            <Typography.Text>No Profile Picture</Typography.Text>
                                        </div>
                                    )}

                                    {/* Upload button */}
                                    <Upload
                                        beforeUpload={(file) => {
                                            setProfilePicture(file);
                                            setProfileFileUrl(URL.createObjectURL(file)); // Show the new uploaded file immediately
                                            setProfileFileName(file.name); // Update file name
                                            return false;
                                        }}
                                        showUploadList={false}
                                    >
                                        {/* Display file name if available */}
                                        {profileFileName && (
                                            <Typography.Text style={{ marginTop: '8px' }}>
                                                {profileFileName}
                                            </Typography.Text>
                                        )}
                                        <Button
                                            icon={<UploadOutlined />}
                                            style={{ marginTop: '10px', display: 'block', borderRadius: '6px' }}
                                        >
                                            Upload Profile Picture
                                        </Button>
                                    </Upload>


                                </div>
                            </Form.Item>
                            <Divider />
                            <label>Citizen Service Center License</label>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {/* Upload button for license document */}
                                    <Upload
                                        beforeUpload={(file) => {
                                            setLicenseFile(file);
                                            setLicenseFileName(file.name); // Show the new uploaded document name
                                            return false;
                                        }}
                                        showUploadList={false}
                                    >
                                        <Button
                                            icon={<UploadOutlined />}
                                            style={{ marginTop: '10px', display: 'block', borderRadius: '6px' }}
                                        >
                                            Upload License Document
                                        </Button>
                                    </Upload>

                                    {/* Display the uploaded license document's name */}
                                    {licenseFileName && (
                                        <Typography.Text style={{ marginTop: '8px' }}>
                                            {licenseFileName}
                                        </Typography.Text>
                                    )}

                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>

                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                if (licenseFileUrl) {
                                                    setPreviewType('license');
                                                    setPreviewVisible(true);
                                                }
                                            }}
                                        >
                                            Preview License Document
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>


                        </Card>

                        <Card title="Address Verification">
                            <Form layout="vertical" autoComplete="off">
                                <Form.Item label="Address" required>
                                    <Input
                                        name="address"
                                        placeholder="Enter address"
                                        value={address}
                                        onChange={handleAddressChange}
                                    />
                                </Form.Item>
                                <AgentMapComponent address={address} onLocationChange={handleLocationChange} />
                                <Button
                                    type="primary"
                                    disabled={!address}
                                    block
                                    onClick={handleVerifyAddress}
                                    style={{ marginTop: '10px' }}
                                >
                                    Verify Address
                                </Button>
                            </Form>
                        </Card>

                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Button
                                type="primary"
                                onClick={handleSubmit}
                                disabled={!isAddressVerified || !locationData}
                            >
                                Update Profile
                            </Button><br />
                            <small style={{ color: "#ff4d4f" }}>
                                Please verify your address to enable profile updates.
                            </small>
                        </div>

                        {/* Preview Modal */}
                        <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={() => setPreviewVisible(false)}
                            title={previewType === 'profile' ? 'Profile Picture' : 'License Document'}
                        >
                            {previewType === 'profile' && profileFileUrl ? (
                                <img alt="Profile" style={{ width: '100%' }} src={profileFileUrl} />
                            ) : (
                                <iframe
                                    src={licenseFileUrl}
                                    title="License Document"
                                    style={{ width: '100%', height: '500px' }}
                                />
                            )}
                        </Modal>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
}
