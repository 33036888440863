import React from 'react';
import { Layout, Grid } from 'antd';
import { Outlet } from 'react-router-dom';
import AgentCustomeHeader from './AgentCustomeHeader';
import { AGENT } from '../../../comman/Constanst';
import { useAuth } from '../../../context/AuthProvider';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const AgentCustomLayout = () => {
    const screens = useBreakpoint(); // Get screen size
    const { role, isAuthenticated } = useAuth();

    // Conditional padding based on screen size
    const paddingStyle = {
        padding: screens.md ? '10px 50px' : '10px 10px',
    };

    return (
        
        <Layout style={{ minHeight: '100vh' }}>
            <AgentCustomeHeader />
            <Content style={paddingStyle}>
                {
                    (isAuthenticated && role ===AGENT) &&
                    <Outlet/>
                }
            </Content>
        </Layout>
    );
};

export default AgentCustomLayout;
