import React, { useState, useEffect } from 'react';
import { Upload, Button, Form, message, Modal, Spin, Divider, Row, Col, Typography } from 'antd';
import { InboxOutlined, EyeOutlined } from '@ant-design/icons';
import { useGetAgentDocumentsMutation } from '../../../../store/RTK_query/agent.api.query';

const { Title, Paragraph } = Typography;

const AgentDocuments = ({ profilePicture, setProfilePicture, citizenServiceCentreLicense, setCitizenServiceCentreLicense, agentEmail }) => {
    const [licenseFileUrl, setLicenseFileUrl] = useState(null);
    const [licenseFileName, setLicenseFileName] = useState(null);
    const [profileFileUrl, setProfileFileUrl] = useState(null);
    const [profileFileName, setProfileFileName] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewType, setPreviewType] = useState('');
    
    const [getAgentDocuments, { isLoading }] = useGetAgentDocumentsMutation();
    const fileTypes = ['application/pdf', 'image/jpeg'];

    const beforeUpload = (file, type) => {
        const isAllowedFormat = fileTypes.includes(file.type);

        if (!isAllowedFormat) {
            message.error('You can only upload PDF or JPG file!');
            return Upload.LIST_IGNORE;
        }

        if (type === 'license') {
            setCitizenServiceCentreLicense(file);
            setLicenseFileUrl(URL.createObjectURL(file));
            setLicenseFileName(file.name);
        } else if (type === 'profile') {
            setProfilePicture(file);
            setProfileFileUrl(URL.createObjectURL(file));
            setProfileFileName(file.name);
        }

        return false; // Prevent automatic upload
    };

    const handleGetDocuments = async () => {
        try {
            const response = await getAgentDocuments({ email: agentEmail }).unwrap();
            const licenseBlob = new Blob([response.license], { type: 'application/pdf' });
            const profileBlob = new Blob([response.profile], { type: 'image/jpeg' });

            setLicenseFileUrl(URL.createObjectURL(licenseBlob));
            setLicenseFileName('license.pdf');
            setProfileFileUrl(URL.createObjectURL(profileBlob));
            setProfileFileName('profile.jpg');

        } catch (error) {
            message.error('Error retrieving files');
        }
    };

    useEffect(() => {
        if (agentEmail)
            handleGetDocuments();
    }, [agentEmail]);

    const handlePreview = (type) => {
        setPreviewType(type);
        setPreviewVisible(true);
    };

    return (
        <div style={{ padding: '20px', maxWidth: '700px', margin: 'auto' }}>
            <Title level={3} style={{ textAlign: 'center' }}>Upload Agent Documents</Title>
            <Paragraph style={{ textAlign: 'center' }}>
                Please upload the required documents for the agent.
            </Paragraph>

            <Form layout="vertical">
                <Form.Item label="Profile Picture" required>
                    <Upload
                        beforeUpload={(file) => beforeUpload(file, 'profile')}
                        maxCount={1}
                        showUploadList={false}
                    >
                        <Button style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={<InboxOutlined />}>
                            Upload Profile Picture
                        </Button>
                    </Upload>
                    {profileFileName && (
                        <div style={{ marginTop: '10px' }} className='space-between'>
                            <p><strong>Uploaded Profile Picture:</strong> {profileFileName}</p>
                            {profileFileUrl && (
                                <Button icon={<EyeOutlined />} onClick={() => handlePreview('profile')} style={{ marginLeft: '10px' }}>
                                    Preview
                                </Button>
                            )}
                        </div>
                    )}
                </Form.Item>

                {isLoading && <Spin tip="Loading document..." />}

                <Divider dashed />

                <Form.Item label="Citizen Service Centre License" required>
                    <Upload
                        beforeUpload={(file) => beforeUpload(file, 'license')}
                        maxCount={1}
                        showUploadList={false}
                    >
                        <Button style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={<InboxOutlined />}>
                            Upload Citizen Service Centre License
                        </Button>
                    </Upload>
                    {licenseFileName && (
                        <div className='space-between'>
                            <p><strong>Uploaded License Document:</strong> {licenseFileName}</p>
                            {licenseFileUrl && (
                                <Button icon={<EyeOutlined />} onClick={() => handlePreview('license')} style={{ marginLeft: '10px' }}>
                                    Preview
                                </Button>
                            )}
                        </div>
                    )}
                </Form.Item>
            </Form>

            <Modal
                visible={previewVisible}
                title={previewType === 'profile' ? 'Profile Picture Preview' : 'License Document Preview'}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
                width={800}
            >
                {previewType === 'profile' && profileFileUrl && (
                    <img src={profileFileUrl} alt="Profile Preview" style={{ width: '100%' }} />
                )}
                {previewType === 'license' && licenseFileUrl && (
                    licenseFileName.endsWith('.pdf') ? (
                        <iframe src={licenseFileUrl} title="PDF Preview" style={{ width: '100%', height: '500px' }}></iframe>
                    ) : (
                        <img src={licenseFileUrl} alt="License Preview" style={{ width: '100%' }} />
                    )
                )}
            </Modal>
        </div>
    );
};

export default AgentDocuments;
