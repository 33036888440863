import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "./RTK_query/user.api.query";
import { agentTypeApi } from "./RTK_query/agent_type.api.query";
import { serviceApi } from "./RTK_query/service.api.query";
import { agentApi } from "./RTK_query/agent.api.query";
import { orderApi } from "./RTK_query/order.api.query";
import { invoiceApi } from "./RTK_query/invoice.api.query";
import UpdateData from "./UpdateData";


const store = configureStore({
    reducer:{
        updateData:UpdateData,
        userApi:userApi.reducer,
        agentTypeApi:agentTypeApi.reducer,
        serviceApi:serviceApi.reducer,
        agentApi:agentApi.reducer,
        orderApi:orderApi.reducer,
        invoiceApi:invoiceApi.reducer,

    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat([
            userApi.middleware,
            agentTypeApi.middleware,
            serviceApi.middleware,
            agentApi.middleware,
            orderApi.middleware,
            invoiceApi.middleware,
        ]),
});

export default store;