import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getToken } from '../../comman/Actions';
import { API_KEY, BASE_URL, HOST, ORIGIN } from '../../comman/Constanst';

export const orderApi = createApi({
    reducerPath : 'orderApi',
    baseQuery : fetchBaseQuery({
        baseUrl:BASE_URL,
        prepareHeaders: (headers) => {
            const token = getToken();
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
            headers.set('apikey', API_KEY);
            headers.set('host',HOST);
            headers.set('origin',ORIGIN);
            return headers;
          },
    }),
    tagTypes : ['orders'],
    endpoints : (builder) => ({
      
        getOrders : builder.query({
            query :()=>({
                url: `/admin/getOrders`,
                method: 'GET',
            }),
            providesTags:['orders']
        }),

        getUserOrders : builder.query({
            query :()=>({
                url: `/user/getOrders`,
                method: 'GET',
            }),
            providesTags:['orders']
        }),

        getAgentOrders : builder.query({
            query :(data)=>({
                url: `/agent/getAgentOrders?page=${data?.page}&limit=${data?.limit}&status=${data?.status}`,
                method: 'POST',
            }),
            providesTags:['orders']
        }),
        
        updateOrderDocServiceStatus :  builder.mutation({
            query: (data) => ({
                url: `/agent/updateOrderDocServiceStatus?orderDocServiceId=${data?.orderDocServiceId}&status=${data?.status}`,
                method: 'POST',
            }),
            invalidatesTags: ['orders']
        }),
       
        updateOrderDocServiceStatusByAdmin :  builder.mutation({
            query: (data) => ({
                url: `/admin/updateOrderDocServiceStatusByAdmin?orderDocServiceId=${data?.orderDocServiceId}&status=${data?.status}`,
                method: 'POST',
            }),
            invalidatesTags: ['orders']
        }),
       
        saveCodOrder : builder.mutation({
            query :(data)=>({
                url: `/user/placeCodOrder`,
                method: 'post',
                body:data,
            }),
            invalidatesTags:['userDetails']
        }),

    })
    
})

export const { useGetOrdersQuery, useGetUserOrdersQuery,useGetAgentOrdersQuery, useUpdateOrderDocServiceStatusMutation, useUpdateOrderDocServiceStatusByAdminMutation,useSaveCodOrderMutation } = orderApi