import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, Circle, useLoadScript } from '@react-google-maps/api';
import axios from 'axios';
import { Alert, Button } from 'antd';
import { AimOutlined } from '@ant-design/icons';

const MapComponent = ({ address, onLocationChange }) => {
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [formattedAddress, setFormattedAddress] = useState('');
    const [locationDetails, setLocationDetails] = useState({ city: '', state: '', country: '' });
    const [error, setError] = useState(null);
    const [isLocationEnable, setIsLocationEnable] = useState(false);

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
    });

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCoordinates({ lat: latitude, lng: longitude });
                    reverseGeocode(latitude, longitude);
                },
                () => {
                    setIsLocationEnable(true);

                    // Default location to New Delhi, India
                    setCoordinates({ lat: 28.6139, lng: 77.209 });
                    setLocationDetails({ city: 'New Delhi', state: 'Delhi', country: 'India' });
                    reverseGeocode(28.6139, 77.209);
                }
            );
        } else {
            // Default to New Delhi if geolocation is not supported
            setCoordinates({ lat: 28.6139, lng: 77.209 });
            setLocationDetails({ city: 'New Delhi', state: 'Delhi', country: 'India' });
            reverseGeocode(28.6139, 77.209);
        }
    }, []);

    // Watch for address prop changes
    useEffect(() => {
        if (address) {
            geocodeAddress(address);
        }
    }, [address]);

    const geocodeAddress = async (inputAddress) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json`,
                {
                    params: {
                        address: inputAddress,
                        key: googleMapsApiKey,
                    },
                }
            );

            if (response.data.status === 'OK' && response.data.results.length > 0) {
                const location = response.data.results[0].geometry.location;
                setCoordinates({ lat: location.lat, lng: location.lng });
                setFormattedAddress(response.data.results[0].formatted_address);
                const addressComponents = response.data.results[0].address_components;
                extractLocationDetails(addressComponents);

                onLocationChange({
                    formattedAddress: response.data.results[0].formatted_address,
                    lat: location.lat,
                    lng: location.lng,
                    ...extractLocationDetails(addressComponents),
                });
                setError(null);
            } else {
                setError('Invalid address. Please try again.');
            }
        } catch (error) {
            setError('Error fetching geocode. Please try again later.');
        }
    };

    const reverseGeocode = async (lat, lng) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json`,
                {
                    params: {
                        latlng: `${lat},${lng}`,
                        key: googleMapsApiKey,
                    },
                }
            );

            if (response.data.status === 'OK' && response.data.results.length > 0) {
                const address = response.data.results[0].formatted_address;
                setFormattedAddress(address);
                const addressComponents = response.data.results[0].address_components;
                extractLocationDetails(addressComponents);

                onLocationChange({
                    formattedAddress: address,
                    lat: lat,
                    lng: lng,
                    ...extractLocationDetails(addressComponents),
                });
                setError(null);
            } else {
                setError('Unable to find address for this location.');
            }
        } catch (error) {
            setError('Error fetching address. Please try again later.');
        }
    };

    const handleMapClick = (event) => {
        const clickedLat = event.latLng.lat();
        const clickedLng = event.latLng.lng();
        setCoordinates({ lat: clickedLat, lng: clickedLng });

        reverseGeocode(clickedLat, clickedLng);
    };

    const extractLocationDetails = (addressComponents) => {
        let city = '';
        let state = '';
        let country = '';

        addressComponents.forEach((component) => {
            if (component.types.includes('locality')) {
                city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name;
            } else if (component.types.includes('country')) {
                country = component.long_name;
            }
        });

        setLocationDetails({ city, state, country });
        return { city, state, country };
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCoordinates({ lat: latitude, lng: longitude });
                    reverseGeocode(latitude, longitude);
                },
                () => {
                    setError('Unable to retrieve your location.');
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div style={{ position: 'relative', width: '100%', height: '300px', borderRadius: '8px' }}>
            {error ? (
                <Alert
                    message={error}
                    type="error"
                    showIcon
                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}
                />
            ) : (
                <>
                    <GoogleMap
                        center={coordinates.lat ? coordinates : { lat: 37.7749, lng: -122.4194 }} // Default center
                        zoom={14}
                        mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '8px' }}
                        onClick={handleMapClick}
                    >
                        {coordinates.lat && (
                            <>
                                <Marker position={coordinates} />
                                {/* <Circle
                                    center={coordinates}
                                    radius={5000}
                                    options={{
                                        fillColor: '#FF0000',
                                        fillOpacity: 0.1,
                                        strokeColor: '#FF0000',
                                        strokeOpacity: 0.6,
                                        strokeWeight: 2,
                                        clickable: false,
                                    }}
                                /> */}
                            </>
                        )}
                    </GoogleMap>
                    {isLocationEnable && (
                        <div style={{ color: "red" }}>
                            <small>
                                Please enable location and click the <AimOutlined /> button to find your current location.
                            </small>
                        </div>
                    )}
                    <Button
                        shape="circle"
                        icon={<AimOutlined />}
                        onClick={getCurrentLocation}
                        style={{
                            position: 'absolute',
                            bottom: '10px',
                            left: '10px',
                            zIndex: 20,
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default MapComponent;
