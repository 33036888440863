import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../store/RTK_query/user.api.query';
import {
  ADMIN,
  ADMIN_DASHBOARD_BASE_PATH,
  AGENT,
  AGENT_DASHBOARD_BASE_PATH,
  ORDERS_BASE_PATH,
  SERVICE_BASE_PATH,
  USER,
  USER_DASHBOARD_BASE_PATH
} from '../comman/Constanst';
import MessageUtil from '../components/MessageUtil';
import { message } from 'antd';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [role, setRole] = useState(localStorage.getItem('role') || null);
  const [name, setName] = useState(localStorage.getItem('name') || null);

  const navigate = useNavigate();
  const [login] = useLoginMutation();

  useEffect(() => {
    // Function to check if the token is expired
    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        const expiryDate = decodedToken.exp;
        const currentTime = Date.now() / 1000;

        if (expiryDate < currentTime) {
          logout("Your session has expired. Please log in again."); // Log out if the token is expired
        }
      }
    };

    // Initial check
    checkTokenExpiration();

    // Set interval to check token expiration every 5 minutes (300000 milliseconds)
    const intervalId = setInterval(checkTokenExpiration, 300000); // 5 minutes

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [token]);

  const handleLogin = async (credentials) => {
    try {
      const { data } = await login(credentials);
      if (data?.token) {
        setToken(data.token);
        setRole(data.role);
        setName(data?.name);

        // Save data to localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('role', data.role);
        localStorage.setItem('name', data.name);

        // Navigate based on role
        if (data.role === ADMIN) {
          navigate(ADMIN_DASHBOARD_BASE_PATH);
        } else if (data.role === USER) {
          navigate(USER_DASHBOARD_BASE_PATH + SERVICE_BASE_PATH);
        } else if (data?.role === AGENT) {
          navigate(AGENT_DASHBOARD_BASE_PATH + ORDERS_BASE_PATH);
        } else {
          navigate("/");
        }
      } else {
        MessageUtil.error({
          message: 'Invalid Credentials',
          description: "Invalid email or password, use another one.",
          duration: 4,
          placement: 'topRight',
        });
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const logout = (msg) => {
    setToken(null);
    setRole(null);
    setName(null);

    // Remove data from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    navigate('/login');
    message.success(msg || "Logged out! See you again soon!");
  };

  const value = {
    token,
    role,
    name,
    handleLogin,
    logout,
    isAuthenticated: !!token,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);