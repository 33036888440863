import React, { useState } from 'react';
import { Form, Input, Button, Card, Divider, Modal, Row, Col, Switch, Typography, notification, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import ServiceAmountCard from '../../../components/ServiceAmountCard';
import { saveGuestPaymentDetails, savePaymentDetails } from './payment';
import { ORDERS_BASE_PATH, USER, USER_DASHBOARD_BASE_PATH } from '../../../comman/Constanst';
import { useAuth } from '../../../context/AuthProvider';
import { Content } from 'antd/es/layout/layout';
import SEO from '../../../components/SEO';
import { useGetServiceByIdQuery } from '../../../store/RTK_query/service.api.query';
import { showErrorMessage } from '../../../comman/Helper';
import ComponentLoader from '../../../components/ComponentLoader';
import ServiceNotFound from '../../../components/ServiceNotFound';
import MapComponent from '../../../components/MapComponent';
import { useSaveGuestCodOrderMutation } from '../../../store/RTK_query/user.api.query';
import { useSaveCodOrderMutation } from '../../../store/RTK_query/order.api.query';

const PlaceOrderOne = () => {
    const location = useLocation();
    const { role, isAuthenticated } = useAuth();
    let service = {};
    const queryParams = new URLSearchParams(location.search);
    const serviceId = queryParams.get('serviceId');
    const serviceRes = useGetServiceByIdQuery(serviceId);
    const [isPrepaid, setIsPrepaid] = useState(false); // Default to Cash on Delivery
    const [saveGuestCodOrder, guestCodOrderRes] = useSaveGuestCodOrderMutation();
    const [saveCodOrder, codOrderRes] = useSaveCodOrderMutation();
    const handleToggleChange = (checked) => {
        setIsPrepaid(checked);
        console.log(`Payment Type: ${checked ? 'Prepaid' : 'Cash on Delivery'}`);
    };


    if (serviceRes?.isError) {
        showErrorMessage(serviceRes?.error)
    }

    if (serviceRes?.isSuccess) {
        service = serviceRes?.data?.response || {};
    }
    const [form] = Form.useForm();
    const [address, setAddress] = useState('');
    const [locationData, setLocationData] = useState(null);
    const [isAddressVerified, setIsAddressVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleVerifyAddress = () => {
        if (locationData && locationData.formattedAddress && locationData.lat && locationData.lng) {
            setIsAddressVerified(true);
            Modal.success({
                title: 'Address Verified',
                content: (
                    <div>
                        <p><b>Address :</b> {locationData.formattedAddress}</p>
                        <p><b>City :</b> {locationData.city} <br /><b>State :</b> {locationData.state} <br /><b>Country :</b> {locationData.country} </p>
                        <p><b>lat:</b> {locationData.lat}<br /><b>lng:</b> {locationData.lng}</p>
                        <p>The address and location details are verified successfully. Please click submit to proceed.</p>
                    </div>
                ),
            });
        } else {
            setIsAddressVerified(false);
            Modal.error({
                title: 'Verification Failed',
                content: 'Please enter a valid address and ensure the location is correct.',
            });
        }
    };

    const handleSubmit = async (values) => {
        if (!isAddressVerified) {
            Modal.warning({
                title: 'Verify Address First',
                content: 'Please verify the address before submitting the details.',
            });
            return;
        }

        const orderData = {
            ...values,
            address: locationData.formattedAddress,
            latitude: locationData.lat,
            longitude: locationData.lng,
            city: locationData.city,
            state: locationData.state,
            country: locationData.country,
            serviceId: service?.serviceId,
        };

        setLoading(true);
        if (isPrepaid) {
            if (isAuthenticated && role === USER) {
                await savePaymentDetails(orderData,navigate).then(res => {
                    if (res == "done") {
                        form.resetFields();
                        setIsAddressVerified(false);
                    }
                })
            } else {
                await saveGuestPaymentDetails(orderData,navigate).then(res => {
                    if (res == "done") {
                        form.resetFields();
                        setIsAddressVerified(false);
                    }
                })
            }
        } else {
            if (isAuthenticated && role === USER) {
                saveCodOrder(orderData).then(res => {
                    if (res?.data?.status == 0) {
                        navigate(USER_DASHBOARD_BASE_PATH + ORDERS_BASE_PATH);
                        message.success("Order successfully placed.")
                    } else if (res?.data?.status == 1) {
                        notification.error({
                            message: res?.data?.message || 'Order not placed, something wrong!',
                        });
                    } else {
                        notification.error({
                            message: res?.error?.data?.message || 'Order not placed, something wrong on server!',
                        });
                    }
                })
            } else {
                saveGuestCodOrder(orderData).then(res => {
                    if (res?.data?.status === 0) {
                        Modal.success({
                            title: 'Order Placed Successfully',
                            content: (
                                <div>
                                    <p>Your order has been placed successfully!</p>
                                    <p>As a guest, you can track your order using the Order ID provided below. Keep this ID safe for future reference.</p>
                                    <p><b>ORDER_ID : {res?.data?.orderId || 'check your email for order id'}</b></p>
                                </div>
                            ),
                            onOk() {
                                // Navigate to the /track page when the modal is closed
                                message.success("order success, you can track your order using order id.")
                                navigate("/track_order");
                            },
                
                        });
                    } else if (res?.data?.status == 1) {
                        notification.error({
                            message: res?.data?.message || 'Order not placed, something wrong!',
                        });
                    } else {
                        notification.error({
                            message: res?.error?.data?.message || 'Order not placed, something wrong on server!',
                        });
                    }
                })
            }
        }
        setLoading(false);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
        setIsAddressVerified(false);
    };

    const handleLocationChange = (location) => {
        setLocationData(location);
    };

    return (
        <>
            <SEO
                title={service?.serviceName + " | Place Your Order"}
                description="Place an order for document creation and doorstep delivery with our fast and reliable service."
                keywords="place order, document creation, document delivery, order"
            />

            <div style={{ padding: "0px 5%" }}>
                {
                    (isAuthenticated && role === USER) ?

                        <Content style={{ textAlign: "center" }}>
                            <h1 level={2} className='page-title'>
                                Welcome Back! Place Your Order
                            </h1>
                            <h2 className='page-sub-title'>
                                Fill in your order details to continue. Your account makes it easy to track and manage orders.
                            </h2>
                        </Content>
                        :
                        <Content style={{ textAlign: "center" }}>
                            <h1 level={2} className='page-title'>
                                Place Your Order as a Guest
                            </h1>
                            <h3 className='page-sub-title'>
                                Create an account for a faster checkout experience and track your orders easily!
                            </h3>
                        </Content>
                }
                <div className='divider'></div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    style={{ marginTop: "20px" }}
                >
                    {
                        serviceRes?.isLoading ?
                            <ComponentLoader />
                            :
                            serviceRes?.isError ?
                                <ServiceNotFound />
                                :
                                <Row gutter={[16, 16]}>
                                    {/* Contact Details */}
                                    <Col xs={24} md={14}>
                                        {
                                            (isAuthenticated === false || role !== USER) &&
                                            <Card style={{ marginBottom: "10px" }} title="Guest User Details" bordered hoverable>
                                                <Row gutter={6}>
                                                    <Col xs={24} sm={12}>
                                                        <Form.Item
                                                            label="First Name"
                                                            name="firstName"
                                                            rules={[{ required: true, message: 'Please input the first name' }]}
                                                        >
                                                            <Input placeholder="Enter contact number" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12}>
                                                        <Form.Item
                                                            label="Last Name"
                                                            name="lastName"
                                                            rules={[{ required: true, message: 'Please input the last name' }]}
                                                        >
                                                            <Input placeholder="Enter pincode" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Form.Item
                                                    label="Email"
                                                    name="email"
                                                    rules={[{ required: true, message: 'Please input an email!' }]}
                                                >
                                                    <Input
                                                        placeholder="Enter email"
                                                    />
                                                </Form.Item>
                                            </Card>
                                        }
                                        <Card title="Address Details" bordered hoverable>
                                            <div className='space-between' style={{ textAlign: "center" }}>
                                                <Typography.Text>Payment Mode</Typography.Text>
                                                <Switch
                                                    checked={isPrepaid}
                                                    onChange={handleToggleChange}
                                                    checkedChildren="Prepaid"
                                                    unCheckedChildren="Cash on Delivery"
                                                    style={{
                                                        backgroundColor: isPrepaid ? 'var(--main-color)' : 'var(--main-color-2)', // Main color when checked, light gray when unchecked
                                                        borderColor: isPrepaid ? 'var(--main-color)' : 'var(--main-color-2)',
                                                        transition: 'background-color 0.3s, border-color 0.3s' // Smooth transition effect
                                                    }}
                                                />
                                            </div>
                                            <Divider />
                                            <Row gutter={6}>
                                                <Col xs={24} sm={12}>
                                                    <Form.Item
                                                        label="Contact"
                                                        name="contactNumber"
                                                        rules={[{ required: true, message: 'Please input the contact number!' }]}
                                                    >
                                                        <Input placeholder="Enter contact number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={12}>
                                                    <Form.Item
                                                        label="Pincode"
                                                        name="pincode"
                                                        rules={[{ required: true, message: 'Please input the pincode!' }]}
                                                    >
                                                        <Input placeholder="Enter pincode" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Form.Item
                                                label="Address"
                                                name="addressInput"
                                            >
                                                <Input
                                                    placeholder="Enter address"
                                                    onChange={handleAddressChange}
                                                />
                                            </Form.Item>
                                            {locationData?.formattedAddress}


                                            {/* Map Component */}
                                            <MapComponent address={address} onLocationChange={handleLocationChange} />

                                            {!isAddressVerified && (
                                                <Button
                                                    type="primary"
                                                    style={{ marginTop: '40px' }}
                                                    onClick={handleVerifyAddress}
                                                    block
                                                >
                                                    Verify Address
                                                </Button>
                                            )}

                                            {/* Submit Button */}
                                            {isAddressVerified && (
                                                <Row gutter={[16, 16]}>
                                                    <Col xs={24}>
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            style={{ width: '100%' }}
                                                            loading={loading}
                                                            styles={{ marginTop: "42px" }}
                                                            size='large'
                                                        >
                                                            Place Order
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Card>
                                    </Col>

                                    {/* Address Details */}
                                    <Col xs={24} md={10} className='service-details-section'>
                                        <ServiceAmountCard service={service} />
                                    </Col>
                                </Row>
                    }
                </Form>
            </div>
        </>
    );
};

export default PlaceOrderOne;
