import { Button, Card, Col, Input, Modal, Row, Spin, Typography, message, Pagination, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { useGetAgentModifiedSLAMutation, useGetServicesQuery, useSubscribeServiceMutation, useUnsubscribeServiceMutation } from '../../../store/RTK_query/service.api.query';
import DOMPurify from 'dompurify';
import { CheckCircleFilled } from '@ant-design/icons';
import { agentApi, useGetAgentDetailsQuery } from '../../../store/RTK_query/agent.api.query';
import { Content } from 'antd/es/layout/layout';
import { useDispatch } from 'react-redux';

const { Option } = Select;

export default function AgentServices() {
    // Fetching agent details
    const { data: agentData, isLoading: isAgentLoading } = useGetAgentDetailsQuery();
    const [subscribe, subcribeRes] = useSubscribeServiceMutation();
    const [unsubscribe, unsunscribeRes] = useUnsubscribeServiceMutation();
    const dispatch = useDispatch();

    const updateAgent = agentData?.response;

    const [suscribedServiceIds, setSuscribedServiceIds] = useState([]); // Initial empty state

    // Fetching all services
    const services = useGetServicesQuery("ACTIVE_ALL");
    const [getSLA, SLARes] = useGetAgentModifiedSLAMutation();
    const servicesData = services?.data?.response || [];

    const [currentPage, setCurrentPage] = useState(1); // For pagination
    const [pageSize, setPageSize] = useState(20); // Number of items per page
    const [searchTerm, setSearchTerm] = useState('');

    // Use useEffect to update suscribedServiceIds when updateAgent data is available
    useEffect(() => {
        if (updateAgent) {
            const initialSubscribedServiceIds = updateAgent?.docsServices?.map(s => s.serviceId) || [];
            setSuscribedServiceIds(initialSubscribedServiceIds);
        }
    }, [updateAgent]); // Runs when updateAgent changes

    const handleServiceSelect = (serviceId, serviceName) => {
        const modal = Modal.info({
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ marginBottom: '16px' }}>
                        {serviceName + " (SLA)"}
                    </div>
                    <Button
                        type="text"
                        onClick={() => modal.destroy()}
                        style={{ color: '#aaa' }}
                    >
                        &times;
                    </Button>
                </div>
            ),
            content: (
                <div style={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Spin spinning={true} size="large" style={{ marginBottom: '20px' }} />
                    <div style={{ display: 'none' }}>
                        {/* Placeholder for the actual content until it's loaded */}
                    </div>
                </div>
            ),
            okText: 'Close',
            width: '90%',
            style: { maxWidth: '800px' },
        });

        getSLA(serviceId).then(res => {
            if (res?.data?.status === 0) {
                const sanitizedHTML = DOMPurify.sanitize(res?.data?.response?.agreement);
                modal.update({
                    content: (
                        <div style={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column' }}>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    maxHeight: '60vh',
                                    marginBottom: '20px',
                                    padding: '10px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    backgroundColor: '#ffffff'
                                }}
                                dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                            />
                        </div>
                    ),
                    footer: (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button
                                onClick={() => {
                                    handleAcceptService(serviceId);
                                    modal.destroy();
                                }}
                            >
                                Accept Agreement
                            </Button>
                            <Button onClick={() => modal.destroy()}>
                                Not Accept
                            </Button>
                        </div>
                    ),
                });
            }
        }).catch(error => {
            console.error("Error fetching SLA:", error);
            message.error("Failed to load Service Level Agreement!");
            modal.destroy();
        });
    };

    const handleAcceptService = (serviceId) => {
        if (!suscribedServiceIds.includes(serviceId)) {
            subscribe(serviceId).then(res => {
                if (res?.data?.status === 0) {
                    setSuscribedServiceIds([...suscribedServiceIds, serviceId]);
                    message.success("Service successfully subscribed.");
                    dispatch(agentApi.util.invalidateTags(["agent"]));
                } else {
                    message.error("Error happen, try later.")
                }
            })
        }
    };

    const handleRemoveService = (serviceId) => {
        unsubscribe(serviceId).then(res => {
            if (res?.data?.status === 0) {
                setSuscribedServiceIds(suscribedServiceIds.filter((id) => id !== serviceId));
                message.success("Service Unsubscribe.");
                dispatch(agentApi.util.invalidateTags(["agent"]));
            } else {
                message.error("Error happen, try later.")
            }
        })
    };

    // For pagination: Get current page services
    const paginatedServices = servicesData
        ?.filter((service) => service.serviceName.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (

        <>
            <Content style={{ padding: '0 16px' }}>
                {/* Title and Search bar layout with space-between */}
                <>

                    <Typography.Title level={2} style={{ marginTop: "0px", fontSize: '24px' }} className='hide-laptop'>
                        SERVICES
                    </Typography.Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography.Title level={2} style={{ marginTop: "0px", fontSize: '24px' }} className='hide-mobile'>
                            SERVICES
                        </Typography.Title>

                        {/* Search Input */}
                        <Input.Search
                            placeholder="Search for services"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                maxWidth: '400px',
                                width: '100%',
                                borderRadius: '8px'
                            }}
                        />
                    </div>

                </>

                {/* Service cards grid */}
                <Row gutter={[16, 16]}>
                    {paginatedServices.map((service) => (
                        <Col key={service.serviceId} xs={24} sm={12} md={8} lg={6}>
                            <Card
                                hoverable
                                style={{
                                    margin: '5px 0',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
                                    cursor: 'pointer',
                                    height: 'auto', // Adjusted for flexible height on mobile
                                    backgroundColor: suscribedServiceIds.includes(service.serviceId) ? '#f6ffed' : '#fff',
                                    border: suscribedServiceIds.includes(service.serviceId) ? '1px solid #b7eb8f' : '1px solid #d9d9d9',
                                }}
                                onClick={() => handleServiceSelect(service.serviceId, service.serviceName)}
                            >
                                {/* Service Name */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {suscribedServiceIds.includes(service.serviceId) && (
                                        <CheckCircleFilled style={{ color: '#52c41a', marginRight: '8px' }} />
                                    )}
                                    <Typography.Text style={{ fontSize: '16px', fontWeight: '600' }}>
                                        {service.serviceName}
                                    </Typography.Text>
                                </div>

                                {/* Fees and action button */}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '12px',
                                    flexWrap: 'wrap',
                                }}>
                                    <Typography.Text strong>Fees: ₹{service?.agentServiceCharge}</Typography.Text>
                                    {suscribedServiceIds.includes(service.serviceId) ? (
                                        <Button
                                            type="primary"
                                            danger
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveService(service.serviceId);
                                            }}
                                            style={{
                                                padding: "5px 12px",
                                                marginTop: '10px',
                                                borderRadius: '6px',
                                                minWidth: '120px',
                                                fontSize: '14px'
                                            }}
                                        >
                                            Unsubscribe
                                        </Button>
                                    ) : (
                                        <Button
                                            type="default"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleServiceSelect(service.serviceId, service.serviceName);
                                            }}
                                            style={{
                                                marginTop: '10px',
                                                padding: "5px 12px",
                                                borderRadius: '6px',
                                                minWidth: '120px',
                                                fontSize: '14px',
                                                border: '1px solid #52c41a',
                                                color: '#52c41a'
                                            }}
                                        >
                                            Subscribe
                                        </Button>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>

                {/* Pagination aligned to the left */}
                <Row justify="end">
                    <Col>
                        <Pagination
                            style={{ marginTop: '20px' }}
                            current={currentPage}
                            pageSize={pageSize}
                            total={servicesData.length}
                            onChange={(page) => setCurrentPage(page)}
                            showSizeChanger={false}
                        />
                    </Col>
                </Row>
            </Content>
        </>


    );
}
