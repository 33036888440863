import React, { useState } from 'react';
import { Layout, Menu, Button, Drawer, Grid, Badge, Affix, Dropdown, Avatar } from 'antd';
import { MenuOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import Basket from './Basket';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useBasketContext } from '../store/basket';
import { ORDERS_BASE_PATH, RAISE_TICKET_URL, SERVICE_BASE_PATH, USER, USER_DASHBOARD_BASE_PATH } from '../comman/Constanst';
import { useAuth } from '../context/AuthProvider';

const { Header } = Layout;
const { useBreakpoint } = Grid;  // Using Grid for responsive breakpoints

const SimpleHeader = () => {
    const screens = useBreakpoint();  // Get screen size
    const { basket } = useBasketContext();  // Get basket items from context
    const { role, isAuthenticated, name, logout } = useAuth();
    const navigate = useNavigate();

    // Separate states for menu and basket drawers
    const [isMenuDrawerVisible, setMenuDrawerVisible] = useState(false);
    const [isBasketDrawerVisible, setBasketDrawerVisible] = useState(false);

    // Toggle the Menu Drawer visibility
    const toggleMenuDrawer = () => {
        setMenuDrawerVisible(!isMenuDrawerVisible);
        setBasketDrawerVisible(false);  // Ensure basket drawer is closed
    };

    // Toggle the Basket Drawer visibility
    const toggleBasketDrawer = () => {
        setBasketDrawerVisible(!isBasketDrawerVisible);
        setMenuDrawerVisible(false);  // Ensure menu drawer is closed
    };

    const profileMenu = (
        <Menu>
            <Menu.Item key="profile" icon={<UserOutlined />}>
                Profile
            </Menu.Item>
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={() => { logout() }}>
                Logout
            </Menu.Item>

        </Menu>
    );

    return (
        <Affix offsetTop={0}>
            <Header className='padding-x' style={{zIndex:99}} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Mobile Section: Hamburger + Logo */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Show Hamburger Icon on mobile (before logo) */}
                        {!screens.md && (
                            <Button
                                type="text"
                                icon={<MenuOutlined style={{ fontSize: '24px', color: 'white' }} />}
                                onClick={toggleMenuDrawer}
                                className="mobile-menu-icon"
                            />
                        )}

                        {/* Logo */}
                        <div style={{ color: 'white', fontSize: '20px', fontWeight: 'bold', marginLeft: screens.md ? '0' : '10px' }}>
                            <Link to="/" style={{ color: 'white' }}>QikD<b style={{ color: 'var(--main-color-2)' }}>.in</b></Link>
                        </div>
                    </div>

                    {/* Show desktop menu if screen is larger than 'md' */}
                    {screens.md && (
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            style={{ flex: 1, justifyContent: 'flex-start' }}  // Align menu to the left
                        >
                            {
                                (isAuthenticated && role === USER) ?

                                    <>
                                        <Menu.Item key="1">
                                            <Link to={USER_DASHBOARD_BASE_PATH + SERVICE_BASE_PATH}>Services</Link>
                                        </Menu.Item>
                                        <Menu.Item key="2">
                                            <Link to={USER_DASHBOARD_BASE_PATH + ORDERS_BASE_PATH}>Orders</Link>
                                        </Menu.Item>
                                        <Menu.Item key="3">
                                            <Link to={RAISE_TICKET_URL}>Raise Ticket</Link>
                                        </Menu.Item>
                                    </>
                                    :
                                    <>
                                        <Menu.Item key="1">
                                            <Link to="/">Home</Link>
                                        </Menu.Item>
                                        <Menu.Item key="2">
                                            <Link to="/about_us">About Us</Link>
                                        </Menu.Item>
                                        {/* <Menu.Item key="3">
                                            <Link to="/services">Services</Link>
                                        </Menu.Item> */}
                                        {/* <Menu.Item key="4">
                                            <Link to="/contact_us">Contact Us</Link>
                                        </Menu.Item> */}
                                        {/* <Menu.Item key="5">
                                            <Link to="/pricing">Pricing</Link>
                                        </Menu.Item> */}
                                        {/* <Menu.Item key="6">
                                            <Link to="/track_order">Track Orders</Link>
                                        </Menu.Item> */}
                                    </>

                            }

                        </Menu>
                    )}

                    {/* Right Section */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Login and Register Buttons (only visible on desktop) */}
                        {screens.md && (
                            <>

                                {
                                    (isAuthenticated && role === USER) ?

                                        <>
                                            <Dropdown overlay={profileMenu} trigger={['click']}>
                                                <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>
                                                    <UserOutlined /> 
                                                </Avatar>
                                            </Dropdown>
                                        </>
                                        :
                                        <>
                                            <Button onClick={() => { navigate("/login") }} size={"large"} style={{ marginRight: 10 }} type='primary' ghost shape="round" danger>
                                                Login
                                            </Button>

                                            <Button onClick={() => { navigate("/registration") }} shape="round" type="primary" size={"large"} danger>
                                                Register
                                            </Button>
                                        </>
                                }
                            </>

                        )}

                        {/* Basket Icon */}
                        {/* <Badge count={basket.length} size="small" style={{ backgroundColor: '#52c41a' }}>
                            <ShoppingCartOutlined
                                style={{ fontSize: '24px', color: 'white', cursor: 'pointer', marginLeft: '15px' }}
                                onClick={toggleBasketDrawer}  // Open the basket drawer when clicked
                            />
                        </Badge> */}
                    </div>
                </div>

                {/* Drawer for Mobile Menu */}
                <Drawer
                    title="Menu"
                    placement="left"
                    closable={true}
                    onClose={toggleMenuDrawer}
                    visible={isMenuDrawerVisible}
                >
                    <Menu mode="vertical">
                        {
                            (isAuthenticated && role === USER) ?
                                <>
                                    <Menu.Item key="1">
                                        <Link to={USER_DASHBOARD_BASE_PATH + SERVICE_BASE_PATH}>Services</Link>
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <Link to={USER_DASHBOARD_BASE_PATH + ORDERS_BASE_PATH}>Orders</Link>
                                    </Menu.Item>
                                    <Menu.Item key="3">
                                        <Link to={RAISE_TICKET_URL}>Raise Ticket</Link>
                                    </Menu.Item>
                                    <Button onClick={() => { logout() }} size={"large"} style={{ marginRight: 10 }} type='primary' ghost shape="round" danger>
                                        Logout
                                    </Button>
                                </>
                                :
                                <>
                                    <Menu.Item key="1" onClick={toggleMenuDrawer}>
                                        <Link to="/">Home</Link>
                                    </Menu.Item>
                                    <Menu.Item key="2" onClick={toggleMenuDrawer}>
                                        <Link to="/about_us">About Us</Link>
                                    </Menu.Item>
                                    {/* <Menu.Item key="3" onClick={toggleMenuDrawer}>
                                        <Link to="/services">Services</Link>
                                    </Menu.Item>
                                    <Menu.Item key="4" onClick={toggleMenuDrawer}>
                                        <Link to="/contact_us">Contact Us</Link>
                                    </Menu.Item>
                                    <Menu.Item key="5" onClick={toggleMenuDrawer}>
                                        <Link to="/pricing">Pricing</Link>
                                    </Menu.Item> */}
                                    {/* <Menu.Item key="6" onClick={toggleMenuDrawer}>
                                            <Link to="/track_order">Track Orders</Link>
                                        </Menu.Item> */}
                                    <Menu.Item key="7" onClick={toggleMenuDrawer}>
                                        <Link to="/login">Login</Link>
                                    </Menu.Item>
                                    <Menu.Item key="8" onClick={toggleMenuDrawer}>
                                        <Link to="/registration">Register</Link>
                                    </Menu.Item>
                                </>

                        }

                    </Menu>
                </Drawer>

                {/* Basket Drawer */}
                {/* <Drawer
                    title="Your Basket"
                    placement="right"
                    onClose={toggleBasketDrawer}
                    visible={isBasketDrawerVisible}
                    width={300} // Adjust the width of the basket drawer
                >
                    <Basket />}
                </Drawer> */}
            </Header>
        </Affix>
    );
};

export default SimpleHeader;
