// components/DocumentLoader.js
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = () => {
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent background
        zIndex: 1000, // Keep it above other elements
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
      };
    
  const messageStyle = {
    fontSize: '18px',
    color: '#ff4d4f',
    marginTop: '20px',
    fontWeight: 'bold',
  };


  const spinIcon = <LoadingOutlined style={{ fontSize: 52, color: '#ff4d4f' }} spin />;

  return (
    <div style={overlayStyle}>
      <Spin indicator={spinIcon} /><br/>
      <div style={messageStyle}>Please wait...</div>

    </div>
  );
};

export default Loader;
