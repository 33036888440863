// ForgetPassword.js
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, notification } from 'antd';
import { useLocation } from 'react-router-dom';
import { useForgotAgentPasswordMutation, useResetAgentPasswordMutation } from '../store/RTK_query/user.api.query';

const AgentForgetPassword = () => {
    const [isTokenPresent, setIsTokenPresent] = useState(false);
    const [token, setToken] = useState(null);
    const [form] = Form.useForm();
    const location = useLocation();

    const [forgetPassword, forgetPasswordRes] = useForgotAgentPasswordMutation();
    const [resetPassword, resetPasswordRes] = useResetAgentPasswordMutation();

    // Check if the token is present in the URL
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            setToken(token);
            setIsTokenPresent(true);
        }
    }, [location]);

    // Function to handle email submission
    const handleEmailSubmit = async (values) => {
        const { email } = values;

        forgetPassword(email).then(res => {
            if (res?.data?.status === 0) {
                notification.success({
                    message: 'Email Send Successfully',
                    description: 'Reset password link successfully sent to your email!',
                });
                form.resetFields();
            }else{
                notification.error({
                    message: res?.error?.data?.message || "Error",
                    description: res?.error?.data?.description || "something went wrong, try later.",
                });
            }
        })
    };

    // Function to handle password reset submission
    const handlePasswordSubmit = async (values) => {
        const { newPassword, confirmPassword } = values;
        // Add validation for newPassword and confirmPassword
        if (newPassword !== confirmPassword) {
            message.error('Passwords do not match!');
            return;
        }
        resetPassword({token,password:newPassword}).then(res => {
            if (res?.data?.status === 0) {
                notification.success({
                    message: res?.data?.message || "Success",
                    description: res?.error?.data?.description || 'Password updated successfully, login using new credentials.',
                });
                form.resetFields();
            }else{
                notification.error({
                    message: res?.error?.data?.message || "Error",
                    description: res?.error?.data?.description || "something went wrong, try later.",
                });
            }
        })
    };

    return (
        <div style={{ maxWidth: '400px', margin: '50px auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
            <h2 style={{ textAlign: 'center', color: 'var(--main-color)' }}>{isTokenPresent ? 'Reset Password' : 'Forget Password'}</h2>
            <Form form={form} onFinish={isTokenPresent ? handlePasswordSubmit : handleEmailSubmit}>
                {!isTokenPresent && (
                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                    >
                        <Input placeholder="Enter your email" />
                    </Form.Item>
                )}

                {isTokenPresent && (
                    <>
                        <Form.Item
                            name="newPassword"
                            rules={[{ required: true, message: 'Please input your new password!' }]}
                        >
                            <Input.Password placeholder="Enter new password" />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            rules={[{ required: true, message: 'Please confirm your password!' }]}
                        >
                            <Input.Password placeholder="Confirm new password" />
                        </Form.Item>
                    </>
                )}

                <Form.Item>
                    <Button loading={forgetPasswordRes?.isLoading || resetPasswordRes?.isLoading} type="primary" htmlType="submit" block>
                        {isTokenPresent ? 'Reset Password' : 'Send Reset Link'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AgentForgetPassword;
