import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../comman/Constanst'
import { getToken } from '../../comman/Actions'

export const serviceApi = createApi({
    reducerPath: 'serviceApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['service'],
    endpoints: (builder) => ({
        getServices: builder.query({
            query: (status) => ({
                url: `/getDocServices?NOR=${status}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['service']
        }),

        getServiceById : builder.query({
            query: (serviceId) => ({
                url: `/getDocService?serviceId=${serviceId}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['service']
        }),

        getRelatedService : builder.query({
            query: (serviceId) => ({
                url: `/getRelatedServices?serviceId=${serviceId}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['service']
        }),
        
        saveService: builder.mutation({
            query: (data) => ({
                url: `/admin/saveService`,
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['service']
        }),

        updateService: builder.mutation({
            query: (data) => ({
                url: `/admin/updateService`,
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['service']
        }),

        updateServiceStatus: builder.mutation({
            query: (data) => ({
                url: `/admin/updateServiceStatus?serviceId=${data?.serviceId}&status=${data?.status}`,
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['service']
        }),


        getAgentModifiedSLA: builder.mutation({
            query: (serviceId) => ({
                url: `/agent/modifiedSLADetails?serviceId=${serviceId}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['service']
        }),

        getSLA: builder.mutation({
            query: (serviceId) => ({
                url: `/admin/SLADetails?serviceId=${serviceId}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['service']
        }),

        subscribeService: builder.mutation({
            query: (serviceId) => ({
                url: `/agent/subscribeService?serviceId=${serviceId}`,
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['service']
        }),

        unsubscribeService: builder.mutation({
            query: (serviceId) => ({
                url: `/agent/unsubscribeService?serviceId=${serviceId}`,
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags: ['service']
        }),
        

        // updateProduct: builder.mutation({
        //     query: (data) => ({
        //         url: `/updateProductDetails`,
        //         method: 'POST',
        //         body: data,
        //         headers: {
        //             'Authorization': 'Bearer ' + getToken(),
        //         }
        //     }),
        //     invalidatesTags: ['products','searchProducts']
        // }),
    })
})

export const { useGetServicesQuery,useGetRelatedServiceQuery , useSaveServiceMutation, useUpdateServiceMutation,useUpdateServiceStatusMutation, useGetServiceByIdQuery, useGetAgentModifiedSLAMutation,useGetSLAMutation,useSubscribeServiceMutation,useUnsubscribeServiceMutation } = serviceApi