import React, { createContext, useContext, useState, useEffect } from 'react';

// Create BasketContext
const BasketContext = createContext();

// Custom hook to use BasketContext
export const useBasketContext = () => useContext(BasketContext);

// Basket Provider to manage global basket state
export const BasketProvider = ({ children }) => {
    const [basket, setBasket] = useState(() => {
        const savedBasket = localStorage.getItem('basket');
        return savedBasket ? JSON.parse(savedBasket) : [];
    });

    const addToBasket = (service) => {
        setBasket((prev) => {
            const updatedBasket = [...prev, service];
            localStorage.setItem('basket', JSON.stringify(updatedBasket));
            return updatedBasket;
        });
    };

    const removeFromBasket = (serviceId) => {
        setBasket((prev) => {
            const updatedBasket = prev.filter((item) => item.id !== serviceId);
            localStorage.setItem('basket', JSON.stringify(updatedBasket));
            return updatedBasket;
        });
    };

    const clearBasket = () => {
        setBasket([]);
        localStorage.removeItem('basket');
    };

    const getTotalPrice = () => {
        return basket.reduce((total, item) => total + item.serviceCharge, 0);
    };

    useEffect(() => {
        localStorage.setItem('basket', JSON.stringify(basket));
    }, [basket]);

    return (
        <BasketContext.Provider value={{ basket, addToBasket, removeFromBasket, clearBasket, getTotalPrice }}>
            {children}
        </BasketContext.Provider>
    );
};
