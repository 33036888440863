import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getToken } from '../../comman/Actions';
import { API_KEY, BASE_URL, HOST, ORIGIN } from '../../comman/Constanst';

export const invoiceApi = createApi({
    reducerPath : 'invoiceApi',
    baseQuery : fetchBaseQuery({
        baseUrl:BASE_URL,
        prepareHeaders: (headers) => {
            const token = getToken();
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
            headers.set('apikey', API_KEY);
            headers.set('host',HOST);
            headers.set('origin',ORIGIN);
            return headers;
          },
    }),
    tagTypes : ['invoices'],
    endpoints : (builder) => ({
      
        getInvoices : builder.query({
            query :()=>({
                url: `/admin/getInvoices`,
                method: 'GET',
            }),
            providesTags:['invoices']
        })

    })
    
})

export const { useGetInvoicesQuery } = invoiceApi