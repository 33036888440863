import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_KEY, BASE_URL, HOST, ORIGIN } from '../../comman/Constanst'
import { getToken } from '../../comman/Actions';
import { message } from 'antd';

export const agentApi = createApi({
    reducerPath: 'agentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers) => {
            const token = getToken();
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('apikey', API_KEY);
            headers.set('host', HOST);
            headers.set('origin', ORIGIN);
            return headers;
        },
    }),
    tagTypes: ['agent'],
    endpoints: (builder) => ({
        getAgents: builder.query({
            query: () => ({
                url: `/admin/getAgents`,
                method: 'get'
            }),
            providesTags: ['agent']
        }),
        onboardAgent: builder.mutation({
            query: (details) => ({
                url: `/admin/onboardAgent`,
                method: 'post',
                body: details
            }),
            invalidatesTags: ['agent']
        }),
        updateAgent: builder.mutation({
            query: (details) => ({
                url: `/admin/updateAgent`,
                method: 'post',
                body: details
            }),
            invalidatesTags: ['agent']
        }),
        getAgentDetails: builder.query({
            query: () => ({
                url: `/agent/getAgentDetails`,
                method: 'get'
            }),
            providesTags: ['agent']
        }),
        updateAgentProfile: builder.mutation({
            query: (details) => ({
                url: `/agent/updateAgentProfile`,
                method: 'post',
                body: details
            }),
            invalidatesTags: ['agent']
        }),
        // RTK Query Mutation
        getAgentDocuments: builder.mutation({
            query: (data) => ({
                url: `/admin/getAgentDocuments?email=${data?.email}`,
                method: 'GET',
                responseHandler: async (response) => {
                    const contentType = response.headers.get('Content-Type');
        
                    if (contentType && contentType.includes('multipart/form-data')) {
                        // Read the multipart response
                        const formData = await response.formData();
                        const profileBlob = formData.get('profile'); // Get the profile Blob
                        const licenseBlob = formData.get('license'); // Get the license Blob
        
                        // Check if the blobs are retrieved
                        if (!profileBlob || !licenseBlob) {
                            message.error('Documents not found');
                        }
        
                        // Return the blobs as part of the response
                        return {
                            profile: profileBlob,
                            license: licenseBlob
                        };
                    } else {
                        throw new Error('Expected multipart response');
                    }
                },
            }),
            providesTags: ['agent'],
        }),

        getAgentDocumentsSelf: builder.mutation({
            query: () => ({
                url: `/agent/getAgentDocuments`,
                method: 'GET',
                responseHandler: async (response) => {
                    const contentType = response.headers.get('Content-Type');
        
                    if (contentType && contentType.includes('multipart/form-data')) {
                        // Read the multipart response
                        const formData = await response.formData();
                        const profileBlob = formData.get('profile'); // Get the profile Blob
                        const licenseBlob = formData.get('license'); // Get the license Blob
        
                        // Check if the blobs are retrieved
                        if (!profileBlob || !licenseBlob) {
                            message.error('Documents not found');
                        }
        
                        // Return the blobs as part of the response
                        return {
                            profile: profileBlob,
                            license: licenseBlob
                        };
                    } else {
                        throw new Error('Expected multipart response');
                    }
                },
            }),
            providesTags: ['agent'],
        }),

    })
})

export const { useGetAgentsQuery, useOnboardAgentMutation, useUpdateAgentMutation, useGetAgentDetailsQuery, useUpdateAgentProfileMutation, useGetAgentDocumentsMutation, useGetAgentDocumentsSelfMutation } = agentApi