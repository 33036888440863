import React from 'react';
import { Layout, Grid } from 'antd';
import CustomHeader from '../../../components/CustomHeader';
import { Outlet } from 'react-router-dom';


const { Content } = Layout;
const { useBreakpoint } = Grid;

const UserCustomLayout = () => {
    const screens = useBreakpoint(); // Get screen size

    // Conditional padding based on screen size
    const paddingStyle = {
        padding: screens.md ? '10px 50px' : '10px 10px',
    };

    return (
        
        <Layout style={{ minHeight: '100vh' }}>
            <CustomHeader />
            <Content style={paddingStyle}>
                <Outlet/>
            </Content>
        </Layout>
    );
};

export default UserCustomLayout;
