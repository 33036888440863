import MessageUtil from "../components/MessageUtil";
import { getNavigate } from "../context/NavigationProvider";


export const saveDetails = (token, name, role) => {
    localStorage.setItem("crm_token", token);
    localStorage.setItem("crm_name", name);
    localStorage.setItem("role", role);
}


export const removeDetails = () => {
    localStorage.removeItem("crm_token")
    localStorage.removeItem("crm_name");
    localStorage.removeItem("role");
}

export const getToken = () => {
    const token = localStorage.getItem('token'); // Get token from localStorage
    if (token !== null) {
        return token;
    } else {
        window.location.assign("/login");
        return null;
    }
}

export const getName = () => {
    return localStorage.getItem("crm_name")
}
export const getRole = () => {
    return localStorage.getItem("role")
}

export const logout = (dispatch) => {
    console.log("LOGOUTX_1")
    MessageUtil.success({
        message: 'Bye bye!!',
        description: "Your successfully logout.",
        duration: 2,
        placement: 'topRight',
    });
    removeDetails();
    // dispatch(userApi.util.invalidateTags(['userDetails']));
    // dispatch(staffApi.util.invalidateTags(['staffDetails']));
    // dispatch(leadApi.util.invalidateTags(['leadDetails']));
    const navigate = getNavigate();
    if (navigate)
        navigate("/login");
    else
        window.location.assign("/login");

}

export const getDocuments = async (
    getAgentDocuments,
    email,
    setProfileFileUrl,
    setLicenseFileUrl,
    setProfileFileName,
    setLicenseFileName
) => {
    try {
        const response = await getAgentDocuments({ email }).unwrap();

        console.log("Full Response:", response); // Log the entire response

        // Check if response contains the expected data
        if (response && response.profile && response.license) {
            // Process Profile Picture
            const profileBlob = new Blob([response.profile], { type: 'image/jpeg' });
            const profileUrl = URL.createObjectURL(profileBlob);
            setProfileFileUrl(profileUrl);
            setProfileFileName(response.profile.name || 'Profile Picture');

            // Process License Document
            const licenseBlob = new Blob([response.license], { type: 'application/pdf' });
            const licenseUrl = URL.createObjectURL(licenseBlob);
            setLicenseFileUrl(licenseUrl);
            setLicenseFileName(response.license.name || 'License Document');
            return true; // Indicate success
        } else {
            console.error("Documents not found in response:", response);
            return false; // Handle case where documents are not found
        }
    } catch (error) {
        console.error("Error fetching documents:", error);
        return false; // Handle error case
    }
};
