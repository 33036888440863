import axios from "axios";
//import logo from '../../files/imgs/manageEcom-logo.png'
import { Modal, message } from "antd";
import { BASE_URL, ORDERS_BASE_PATH, USER_DASHBOARD_BASE_PATH } from "../../../comman/Constanst";
import { getToken } from "../../../comman/Actions";
import MessageUtil from "../../../components/MessageUtil";


export const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src;
        script.crossOrigin = 'anonymous'
        script.onload = () => {
            resolve(true);
        }
        script.onerror = () => {
            resolve(false);
        }
        document.body.appendChild(script);
    })
}

export const savePaymentDetails = async (data,navigate) => {

    try {
        // Get the token
        const token = getToken();
        // Define headers conditionally
        const headers = {};
        headers['Authorization'] = `Bearer ${token}`;
        return await axios.post(`${BASE_URL}user/placePrepaidOrder`, data, { headers }).then(
            async (response) => {
                if (response?.data?.status === 1 || response?.data?.status == 1) {
                    MessageUtil.error({
                        message: 'Invalid',
                        description: response?.data?.message,
                        duration: 10,
                        placement: 'topRight',
                    });
                    return;
                }
                //razorpay payment integration
                const result = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
                if (!result) {
                    message.error("network issue...");
                    // setLoader({type:null,loading:false});
                    return;
                }
                // console.log("amt " + JSON.stringify(response.data));
                const Razorpay_payment = JSON.parse(response?.data?.RZ_order);
                const order_ref_id = response?.data?.orderRefId;
                const user_id = response?.data?.userId;
                const name = response?.data?.userName;
                const contact = response?.data?.userContact;
                const email = response?.data?.userEmail

                //console.log("order id : "+Razorpay_payment.id);
                const options = {
                    key: response?.data?.rzKey,
                    currency: "INR",
                    name: "QuickDock payment gateway",
                    description: "generate quickly and fast delivered",
                    //  image: logo,
                    order_id: Razorpay_payment.id,
                    handler: function (response) {

                        const payment_id = response.razorpay_payment_id;
                        const rz_order_id = response.razorpay_order_id;
                        const signature = response.razorpay_signature;

                        if (response.razorpay_payment_id) {
                            //update the states of order payment
                            const paymentObject = {
                                "rzPaymentId": payment_id,
                                "rzOrderId": rz_order_id,
                                "rzSignature": signature,
                                "orderID": Razorpay_payment.id,
                                "orderRefId": order_ref_id,
                                "userId": user_id
                            }
                            axios.post(`${BASE_URL}updatePaymentStatus`, paymentObject).then(
                                (response) => {
                                
                                        message.success("Payment Success, order successfully placed.");
                                    //setLoader({type:null,loading:false});
                                    navigate(USER_DASHBOARD_BASE_PATH + ORDERS_BASE_PATH);
                                    return "done";
                                },
                                (error) => {
                                    console.log(error);
                                    message.error("Payment Faild");
                                    return null;
                                    //setLoader({type:null,loading:false});
                                }
                            )

                        }
                    },
                    prefill: {
                        name: name,
                        email: email,
                        contact: contact,
                    },
                    theme: {
                        color: "#1976d2"
                    }
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();

                //setLoader({type:null,loading:false});
                return null;

            },
            (error) => {
                // setLoader({type:null,loading:false});
                message.error("Something went wrong!!");
                console.error(error);
                return null;
            }
        )
    } catch (error) {
        console.log(error);
        message.error("something went wrong.")
    }
}


export const saveGuestPaymentDetails = async (data,navigate) => {

    try {
        const headers = {};
        headers['Authorizationx'] = `Bearer gustUser-tymsdbfmdbf`;
        return await axios.post(`${BASE_URL}placePrepaidOrder`, data, { headers }).then(
            async (response) => {
                if (response?.data?.status === 1 || response?.data?.status == 1) {
                    MessageUtil.error({
                        message: 'Invalid',
                        description: response?.data?.message,
                        duration: 10,
                        placement: 'topRight',
                    });
                    return;
                }
                //razorpay payment integration
                const result = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
                if (!result) {
                    message.error("network issue...");
                    // setLoader({type:null,loading:false});
                    return;
                }
                // console.log("amt " + JSON.stringify(response.data));
                const Razorpay_payment = JSON.parse(response?.data?.RZ_order);
                const order_ref_id = response?.data?.orderRefId;
                const user_id = response?.data?.userId;
                const name = response?.data?.userName;
                const contact = response?.data?.userContact;
                const email = response?.data?.userEmail

                //console.log("order id : "+Razorpay_payment.id);
                const options = {
                    key: response?.data?.rzKey,
                    currency: "INR",
                    name: "QuickDock payment gateway",
                    description: "generate quickly and fast delivered",
                    //  image: logo,
                    order_id: Razorpay_payment.id,
                    handler: function (response) {

                        const payment_id = response.razorpay_payment_id;
                        const rz_order_id = response.razorpay_order_id;
                        const signature = response.razorpay_signature;

                        if (response.razorpay_payment_id) {
                            //update the states of order payment
                            const paymentObject = {
                                "rzPaymentId": payment_id,
                                "rzOrderId": rz_order_id,
                                "rzSignature": signature,
                                "orderID": Razorpay_payment.id,
                                "orderRefId": order_ref_id,
                                "userId": user_id
                            }
                            axios.post(`${BASE_URL}updatePaymentStatus`, paymentObject).then(
                                (response) => {

                                    Modal.success({
                                        title: 'Order Placed Successfully',
                                        content: (
                                            <div>
                                                <p>Your order has been placed successfully!</p>
                                                <p>As a guest, you can track your order using the Order ID provided below. Keep this ID safe for future reference.</p>
                                                <p><b>Order ID:  {order_ref_id}</b></p>
                                            </div>
                                        ),
                                        onOk() {
                                            // Navigate to the /track page when the modal is closed
                                            message.success("order success, you can track your order using order id.")
                                            navigate("/track_order");
                                        },
                                    });
                                    
                                    //setLoader({type:null,loading:false});
                                    return "done";
                                },
                                (error) => {
                                    console.log(error);
                                    message.error("Payment Faild");
                                    return null;
                                    //setLoader({type:null,loading:false});
                                }
                            )

                        }
                    },
                    prefill: {
                        name: name,
                        email: email,
                        contact: contact,
                    },
                    theme: {
                        color: "#1976d2"
                    }
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();

                //setLoader({type:null,loading:false});
                return "done";

            },
            (error) => {
                // setLoader({type:null,loading:false});
                message.error("Something went wrong!!");
                console.error(error);
                return null;
            }
        )
    } catch (error) {
        console.log(error);
        message.error("something went wrong.")
    }
}
