import React from 'react';
import { Row, Col, Typography, Divider, Card, Layout } from 'antd';
import { MailOutlined, PhoneOutlined, EnvironmentOutlined, FacebookOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';
import logo1 from '../assets/imgs/logo-1.png';
import { SERVICE_DETAILS_BASE_PATH } from '../comman/Constanst';
const { Title, Text } = Typography;
const { Meta } = Card;

const Footer = () => {
  return (
    <>
      <Layout.Footer style={{ backgroundColor: '#e2e2e2', padding: '20px 0', boxSizing: 'border-box', marginTop: "100px" }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={4} style={{ color: 'var(--main-color)' }}>Quick Links</Title>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li><a href="/" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Home</a></li>
                {/* <li><a href="/services" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Services</a></li> */}
                {/* <li><a href="/pricing" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Pricing</a></li> */}
                <li><a href="/about_us" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>About Us</a></li>
                <li><a href="/contact_us" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Contact Us</a></li>
              </ul>
            </Col>
            {/* <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={4} style={{ color: 'var(--main-color)' }}>Quick Services</Title>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li><a href={SERVICE_DETAILS_BASE_PATH+"?serviceId=16"} style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Pan Card</a></li>
                <li><a href={SERVICE_DETAILS_BASE_PATH+"?serviceId=1"} style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Aadhaar Card</a></li>
                <li><a href={SERVICE_DETAILS_BASE_PATH+"?serviceId=9"} style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Birth Certificate</a></li>
                <li><a href={SERVICE_DETAILS_BASE_PATH+"?serviceId=8"} style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Passport</a></li>
                <li><a href="/services" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Others</a></li>
              </ul>
            </Col> */}

            <Col xs={24} sm={24} md={24} lg={6}>
              <Title level={4} style={{ color: 'var(--main-color)' }}>Terms and Conditions</Title>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li><a href="/terms_of_service" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Terms of Service</a></li>
                <li><a href="/privacy_policy" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Privacy Policy</a></li>
                <li><a href="/refund_policy" style={{ color: 'var(--vdark-color)', textDecoration: 'none' }}>Refund Policy</a></li>
              </ul>
            </Col>

            <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: 'center' }}>
              <Title level={4} style={{ color: 'var(--main-color)' }}>Website Information</Title>
              <Card
                style={{ background: '#1f1f1f', border: 'none', borderRadius: '8px', textAlign: 'center' }}
                cover={<img alt="Logo" src={logo1} style={{ maxWidth: '150px', margin: "auto" }} />}
              >
                <Meta
                  description={<Text style={{ color: '#fff' }}>We are committed to providing exceptional services. Contact us for more information.</Text>}
                />
              </Card>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <p style={{ color: 'var(--vdark-color)' }}><MailOutlined /> contact@nuvio.in</p>
                <p style={{ color: 'var(--vdark-color)' }}><PhoneOutlined /> +91 86994 92673</p>
                <p style={{ color: 'var(--vdark-color)' }}><EnvironmentOutlined />Shop NO 2 Shakti Dham Apartment Plot No 108, Sector 21 Ghansoli, Ghansoli, Navi Mumbai - 400701</p>
                <div style={{ marginTop: '20px' }}>
                  <FacebookOutlined style={{ fontSize: '20px', color: 'var(--vdark-color)', margin: '0 10px' }} />
                  <TwitterOutlined style={{ fontSize: '20px', color: 'var(--vdark-color)', margin: '0 10px' }} />
                  <LinkedinOutlined style={{ fontSize: '20px', color: 'var(--vdark-color)', margin: '0 10px' }} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Layout.Footer>
      <Layout.Footer style={{ backgroundColor: 'var(--vdark-color)' }}>
        <Row justify="center" >
          <Text style={{ color: '#999' }}>© 2024 Nuvio Technologies Private Limited | All rights reserved.</Text>
        </Row>
      </Layout.Footer>
    </>
  );
};

export default Footer;
