import React, { useState, useEffect } from 'react';
import { Card, Typography, Divider, Row, Col, Space, Statistic, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined, DollarCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useBasketContext } from '../store/basket';
import { BASE_URL } from '../comman/Constanst';
import { getToken } from '../comman/Actions';
import axios from 'axios';

const { Title, Text } = Typography;

export default function ServiceAmountCard({ service }) {
  // Define state for verified order details
  const [verifiedOrderDetails, setVerifiedOrderDetails] = useState(null);

  // Fetch service billing details
  //   const getServiceBillingDetails = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${BASE_URL}calculateServiceBilling`, // Replace with your API endpoint
  //         { docsServicesList: [service] }, // Replace with actual data structure
  //         {
  //           headers: {
  //             Authorization: `Bearer ${getToken()}` // Attach the JWT token here
  //           }
  //         }
  //       );

  //       if (response.data.status === 0) {
  //         setVerifiedOrderDetails(response.data.response);
  //       } else {
  //         message.error('Something went wrong, Please try again.');
  //       }
  //     } catch (error) {
  //       console.error('Error:', error); // Handle error
  //       message.error('Error fetching details. Please try again later.');
  //     }
  //   };

  //   useEffect(() => {
  //     if (service) {
  //       getServiceBillingDetails();
  //     }
  //   }, [service]); // Re-fetch whenever the service changes

  return (
    <Card
      title={<Title level={3} style={{ color: 'var(--main-color)', textAlign: 'center' }}>Service Details</Title>}
      bordered
      hoverable
      style={{ backgroundColor: '#ffffff', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div style={{display:"flex", justifyContent:"center"}}>
            <img
              src={service?.imgUrl}
              style={{
                borderRadius: '50%',
                width: '150px',
                height: '150px',
                marginBottom: '-15px',
                objectFit: 'contain',
              }}
            />
          </div>
          <Title level={4} style={{ marginBottom: '10px' }}>{service?.serviceName}</Title>
          <Text>
            <ClockCircleOutlined style={{ marginRight: '5px' }} />
            <b>Time Required:</b> {service?.requiredTime}
          </Text>
        </Col>

        <Col span={24}>
          <Text>
            <DollarCircleOutlined style={{ marginRight: '5px', color: 'green' }} />
            <b>Service Charge:</b> ₹{service?.serviceCharge}
          </Text>
        </Col>

        <Col span={24}>
          <Divider />
          <Row justify="space-between" align="middle">
            <Col>
              <Text>
                <InfoCircleOutlined style={{ marginRight: '5px' }} />
                <b>Shipping Fee:</b>
              </Text>
            </Col>
            <Col>
              <Text style={{ color: 'green', fontSize: '16px' }}>₹{service?.shippingFee}</Text>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between" align="middle">
            <Col>
              <Text style={{ fontSize: '18px' }}>
                <b>Total Cost:</b>
              </Text>
            </Col>
            <Col>
              <Statistic
                value={service?.serviceCharge + service?.shippingFee || 0}
                prefix="₹"
                precision={2}
                valueStyle={{ fontWeight: 'bold', fontSize: '20px', color: 'var(--main-color)' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
