import React from 'react';
import { Button, Divider, Input, Modal } from 'antd';
import AgentMapComponent from '../../../../components/AgentMapComponent';

const VerifyLocationStep = ({ address, setAddress, locationData, setLocationData, isAddressVerified, setIsAddressVerified }) => {
    const handleVerifyAddress = () => {
        if (locationData?.formattedAddress && locationData?.lat && locationData?.lng) {
            setIsAddressVerified(true);
            Modal.success({
                title: 'Address Verified',
                content: (
                    <div>
                        <p><b>Address :</b> {locationData?.formattedAddress}</p>
                        <p><b>Latitude:</b> {locationData?.lat}</p>
                        <p><b>Longitude:</b> {locationData?.lng}</p>
                        <p><b>City:</b>{locationData?.city}<br /><b>State:</b>{locationData?.state}<br /><b>Country:</b>{locationData?.country}</p>
                        <p>The address and location details are verified successfully. Please click "Next" to proceed.</p>
                    </div>
                ),
            });
        } else {
            setIsAddressVerified(false);
            Modal.error({ title: 'Verification Failed', content: 'Invalid address or location data.' });
        }
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
        setIsAddressVerified(false);
    };

    const handleLocationChange = (location) => {
        setLocationData(location);
        setIsAddressVerified(false);
    };

    return (
        <>
            <Input placeholder="Enter address" value={ address } onChange={handleAddressChange} />
            <Divider/>
            {locationData?.formattedAddress}
            <AgentMapComponent address={address} onLocationChange={handleLocationChange} />
            <Button style={{margin:"40px 0px 20px 0px"}} type="primary" block onClick={handleVerifyAddress} disabled={isAddressVerified}>
                {isAddressVerified ? 'Verified' : 'Verify Address'}
            </Button>
        </>
    );
};

export default VerifyLocationStep;
