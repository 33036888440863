import React from 'react';
import { Layout, Grid } from 'antd';
import CustomHeader from '../components/CustomHeader';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';


const { Content } = Layout;
const { useBreakpoint } = Grid;

const CustomLayout = () => {
    const screens = useBreakpoint(); // Get screen size


    return (
        <Layout  style={{ minHeight: '100vh' }}>
            <CustomHeader />
            <Content className='padding-x'>
                <Outlet/>
            </Content>
            <Footer/>
        </Layout>
    );
};

export default CustomLayout;
