import { json } from "react-router-dom";
import { removeDetails } from "./Actions";
import MessageUtil from "../components/MessageUtil";
import { getNavigate } from "../context/NavigationProvider";
import { ADMIN_DASHBOARD_BASE_PATH } from "./Constanst";

export const showErrorMessage = (error) => {
    const navigate = getNavigate();
    if(error?.status===403){
        navigate(ADMIN_DASHBOARD_BASE_PATH+"/403")
        console.error("INVALID USER");
        return;
    }
    MessageUtil.error({
        message: error?.data?.message || 'Error!!',
        description: error?.data?.description || 'Something went wrong.',
        duration: 0,
        placement: 'top',
    });
    console.log(error);
    if(error.status===401){
        removeDetails();
        if(navigate)
            navigate("/login");
        else
            window.location.assign("/login")
    }
}

export const showError=(title,msg,duration,location)=>{
    MessageUtil.error({
        message: title,
        description: msg,
        duration: duration,
        placement: location,
    });
}

export const showSuccessMessage = (res , duration) => {
    MessageUtil.success({
        message: res?.data?.message || 'Success !!',
        description: res?.data?.description || '',
        duration: duration || 4,
        placement: 'topRight',
    });
}

//text editor setting
export const toolbarOptions = [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    [{ 'color': [] }, { 'background': [] }], // Add dropdowns for text color and background color
    [{ 'align': [] }], // Add dropdown for text alignment
];

// Utility function to mask the number
export const maskNumber = (number) => {
    if (!number) return '';
    const numberStr = number.toString();
    const last4Digits = numberStr.slice(-4);
    const maskedDigits = '*'.repeat(numberStr.length - 4);
    return maskedDigits + last4Digits;
};

export const validateBasicDetails = (details) => {
    const { firstName, lastName, email, contactNumber } = details;
    return firstName && lastName && email && contactNumber;
};

