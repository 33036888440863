import { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationContext = createContext();

let navigate = null;

export const NavigationProvider = ({ children }) => {
  const navigator = useNavigate();
  navigate = navigator;

  return (
    <NavigationContext.Provider value={navigate}>
      {children}
    </NavigationContext.Provider>
  );
};

export const getNavigate = () => navigate;
