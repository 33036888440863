import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { NavigationProvider } from './context/NavigationProvider';
import { ADMIN_DASHBOARD_BASE_PATH, AGENT_ADD_UPDATE_BASE_PATH, AGENT_BASE_PATH, AGENT_DASHBOARD_BASE_PATH,AGENT_FORGOTE_PASSWORD_PATH,AGENT_PROFILE_BASE_PATH,INVOICE_BASE_PATH,ORDERS_BASE_PATH, PLACE_ORDER_1_BASE_PATH, PLACE_ORDER_BASE_PATH, SERVICE_ADD_UPDATE_BASE_PATH, SERVICE_BASE_PATH, SERVICE_DETAILS_BASE_PATH, USER_DASHBOARD_BASE_PATH } from './comman/Constanst';
import { AuthProvider } from './context/AuthProvider';
import PrivateRoute from './components/PrivateRoute';
import TestPage from './pages/Test';
import PlaceOrderOne from './dashboard/user/order/PlaceOrderOne';
import { BasketProvider } from './store/basket';
import CustomLayout from './pages/CustomLayout';
import UserCustomLayout from './dashboard/user/main_template/UserCustomeLayout';
import "../src/assets/style.css";
import Loader from './components/Loader';
import ScrollToTop from './context/ScrollToTop';
import AgentCustomLayout from './dashboard/agent/main_template/AgentCustomeLayout';
import OrderTabs from './dashboard/agent/orders/OrderTabs';
import './App.css';
import Misssing from './pages/Misssing';
import AddUpdateAgent from './dashboard/admin/Agent/add_update_agent/AddUpdateAgent';
import AgentProfile from './dashboard/agent/profile/AgentProfile';
import AgentServices from './dashboard/agent/services/AgentServices';
import AgentForgetPassword from './pages/AgentForgotPassword';

const Invoice = lazy(() => import('./dashboard/user/UserDashboardPages/Invoice'));
const PwaActions = lazy(() => import('./components/PwaActions'));
const ServiceDetails = lazy(() => import('./pages/ServiceDetails'));
const PricingPage = lazy(() => import('./pages/PricingPage'));
const AdminLogin = lazy(() => import('./pages/AdminLogin'));
const AgentOrders = lazy(() => import('./dashboard/agent/orders/AgentOrders'));
const AgentLogin = lazy(() => import('./pages/AgentLogin'));
const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Unauthorized = lazy(() => import('./pages/Unauthorized'));
const AdminDashboard = lazy(() => import('./dashboard/admin/MainTemplate/AdminDashboard'));
const UserDashboard = lazy(() => import('./dashboard/user/UserDashboardPages/UserDashboard'));
const UserService = lazy(() => import('./dashboard/user/UserDashboardPages/UserService'));
const UserOrders = lazy(() => import('./dashboard/user/UserDashboardPages/UserOrders'));
const AdminOrders = lazy(() => import('./dashboard/admin/Orders/AdminOrders'));
const Agent = lazy(() => import('./dashboard/admin/Agent/Agent'));
const Services = lazy(() => import('./pages/Services'));
const Registration = lazy(() => import('./pages/Registration'));
const Service = lazy(() => import('./dashboard/admin/Services/Service'));
const AddUpdateService = lazy(() => import('./dashboard/admin/Services/AddUpdateService'));
const OrderTracking = lazy(() => import('./pages/OrderTracking'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const RefundPolicy =lazy(()=> import('./pages/RefundPolicy'));

function App() {
  return (
    <div>
      <PwaActions/>
      <BrowserRouter>
        <NavigationProvider>
          <AuthProvider>
            <BasketProvider>
              <Suspense fallback={<Loader />}>  {/* Wrap Routes in Suspense */}
                <ScrollToTop />
                <Routes>
                  
                  <Route path="agent_login" element={<AgentLogin />} />
                  <Route path={AGENT_FORGOTE_PASSWORD_PATH} element={<AgentForgetPassword />} />
                  <Route path="admin_login" element={<AdminLogin />} />
                  <Route path="/*"  element={<Misssing/>} />
                  <Route element={<CustomLayout />}>
                    <Route index element={<Home />} />
                    
                    <Route path="login" element={<Login />} />
                    <Route path="services" element={<Services />} />
                    <Route path="about_us" element={<AboutUs />} />
                    <Route path="contact_us" element={<ContactUs />} />
                    <Route path="track_order" element={<OrderTracking />} />
                    <Route path={PLACE_ORDER_1_BASE_PATH} element={<PlaceOrderOne />} />
                    <Route path="privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="terms_of_service" element={<TermsOfService />} />
                    <Route path="refund_policy" element={<RefundPolicy />} />
                    <Route path="test" element={<TestPage />} />
                    <Route path="registration" element={<Registration />} />
                    <Route path="unauthorized" element={<Unauthorized />} />
                    <Route path="pricing" element={<PricingPage/>}/>
                    <Route path={SERVICE_DETAILS_BASE_PATH} element={<ServiceDetails/>}/>
                  </Route>

                  {/* Protected route: AdminDashboard */}
                  <Route
                    path={ADMIN_DASHBOARD_BASE_PATH}
                    element={
                      <PrivateRoute allowedRoles={['ROLE_ADMIN']}>
                        <AdminDashboard />
                      </PrivateRoute>
                    }
                  >
                    <Route path={ADMIN_DASHBOARD_BASE_PATH + AGENT_ADD_UPDATE_BASE_PATH} element={<AddUpdateAgent />} />
                    <Route path={ADMIN_DASHBOARD_BASE_PATH + AGENT_BASE_PATH} element={<Agent />} />
                    <Route path={ADMIN_DASHBOARD_BASE_PATH + SERVICE_ADD_UPDATE_BASE_PATH} element={<AddUpdateService />} />
                    <Route path={ADMIN_DASHBOARD_BASE_PATH + SERVICE_BASE_PATH} element={<Service />} />
                    <Route path={ADMIN_DASHBOARD_BASE_PATH + ORDERS_BASE_PATH} element={<AdminOrders />} />
                  </Route>


                  {/* Protected route: UserDashboard */}
                  <Route
                    path={AGENT_DASHBOARD_BASE_PATH}
                    element={
                      <PrivateRoute allowedRoles={['ROLE_AGENT']}>
                        <AgentCustomLayout />
                      </PrivateRoute>
                    }
                  >
                    <Route  element={<>AGENT DASHBOARD</>} />
                    <Route index path={AGENT_DASHBOARD_BASE_PATH + ORDERS_BASE_PATH} element={<OrderTabs />} />
                    <Route path={AGENT_DASHBOARD_BASE_PATH + AGENT_PROFILE_BASE_PATH} element={<AgentProfile />} />
                    <Route path={AGENT_DASHBOARD_BASE_PATH + SERVICE_BASE_PATH} element={<AgentServices />} />

                  </Route>


                  {/* Protected route: UserDashboard */}
                  <Route
                    path={USER_DASHBOARD_BASE_PATH}
                    element={
                      <PrivateRoute allowedRoles={['ROLE_USER']}>
                        <UserCustomLayout />
                      </PrivateRoute>
                    }
                  >
                    {/* <Route index element={<UserDashboard />} /> */}
                    <Route index path={USER_DASHBOARD_BASE_PATH + SERVICE_BASE_PATH} element={<UserService />} />
                    <Route path={USER_DASHBOARD_BASE_PATH + ORDERS_BASE_PATH} element={<UserOrders />} />
                    <Route path={USER_DASHBOARD_BASE_PATH + INVOICE_BASE_PATH} element={<Invoice />} />

                  </Route>
                </Routes>

              </Suspense>
            </BasketProvider>
          </AuthProvider>
        </NavigationProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
