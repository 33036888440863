import React from 'react';
import { Card, Button, Typography } from 'antd';
import { ExclamationCircleOutlined, WarningOutlined, CloudSyncOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const ServiceNotFound = ({ title,description }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh', padding: '20px' }}>
      <Card
        style={{
          width: '100%',
          maxWidth: 400,
          textAlign: 'center',
          border: '1px solid #FF0043',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
        bodyStyle={{ padding: '20px' }}
      >
        <Title level={4} style={{ color: '#FF0043', marginBottom: '10px' }}>
          <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
            {title}
        </Title>
        <Paragraph>
          {description}
        </Paragraph>
      </Card>
    </div>
  );
};

export default ServiceNotFound;
