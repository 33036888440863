import React from 'react';
import { Spin, Typography, Space } from 'antd';

const { Text } = Typography;

const ComponentLoader = () => {
  return (
    <div style={loaderContainerStyle}>
      <Space direction="vertical" align="center">
        <Spin size="large"  style={{ color: 'var(--main-color)' }}  />
        <Text style={loaderTextStyle}>Loading, please wait...</Text>
      </Space>
    </div>
  );
};

const loaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh', // Full viewport height
  backgroundColor: '#f0f2f5', // Light background color
};

const loaderTextStyle = {
  fontSize: '18px',
  color: 'var(--main-color)', // Using CSS variable for color
};



export default ComponentLoader;
