import React, { useState } from 'react';
import { Card, Steps, Space, Row, Col, Button, message, Form, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useOnboardAgentMutation, useUpdateAgentMutation } from '../../../../store/RTK_query/agent.api.query';
import { ADMIN_DASHBOARD_BASE_PATH, AGENT_BASE_PATH, UPDATE_TYPE_AGENT } from '../../../../comman/Constanst';
import { useUpdateRequestData } from '../../../../store/UseUpdateRequestData';
import BasicDetailsStep from './BasicDetailsStep';
import VerifyLocationStep from './VerifyLocationStep';
import ChooseServicesStep from './ChooseServicesStep';
import './AddUpdateAgent.css';
import { validateBasicDetails } from '../../../../comman/Helper';
import { Content } from 'antd/es/layout/layout';
import AgentDocuments from './AgentDocuments';

const { Step } = Steps;

const AddUpdateAgent = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const updateAgent = useUpdateRequestData(UPDATE_TYPE_AGENT) || null; 
    const [locationData, setLocationData] = useState(null);
    const [isAddressVerified, setIsAddressVerified] = useState(false);
    const [suscribedServiceIds, setSuscribedServiceIds] = useState(() => {
                return updateAgent ? updateAgent?.docsServices?.map(s => s.serviceId) : [];
            });   
    const [removedServiceIds, setRemovedServiceIds] = useState([]);
    const [newAcceptedServiceIds, setNewAcceptedServiceIds] = useState([]);

    const [citizenServiceCenterLicense, setCitizenServiceCenterLicense] = useState(null);
    const [profilePicture,setProfilePicture] = useState(null);

    const [form] = Form.useForm();
    const [agentDetails, setAgentDetails] = useState({
        firstName: updateAgent?.firstName || '',
        lastName: updateAgent?.lastName || '',
        email: updateAgent?.email || '',
        contactNumber: updateAgent?.contactNumber || '',
    });
    const [address, setAddress] = useState(updateAgent ? updateAgent?.address : "");
    const [onboardAgent, onboardAgentRes] = useOnboardAgentMutation();
    const [updateAgentMutation, updateAgentRes] = useUpdateAgentMutation();
    const navigate = useNavigate();

    // Handle the next step
    const handleNext = () => {
        if (currentStep === 0 && !validateBasicDetails(agentDetails)) {
            message.error('Please fill in all basic details!');
            return;
        }
        if (currentStep === 1 && !isAddressVerified) {
            message.error('Please verify the address before proceeding.');
            return;
        }
        setCurrentStep(currentStep + 1);
    };

    // Handle the previous step
    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };

    // Handle form submission
//     const handleSubmit = () => {
//         const agentData = {
//             ...agentDetails,
//             address: locationData?.formattedAddress,
//             latitude: locationData?.lat,
//             longitude: locationData?.lng,
//             city: locationData?.city,
//             state: locationData?.state,
//             country: locationData?.country,
//             removedDocServiceIds: removedServiceIds,
//             newAcceptedServiceIds: newAcceptedServiceIds,
//             agentId: updateAgent?.agentId,
//         };

//         const formData = new FormData();
//   formData.append('citizenServiceCentreLicense', citizenServiceCentreLicense);
//   formData.append('infoDto', JSON.stringify(agentData)); 

//         if (updateAgent) {
//             updateAgentMutation(formData).then(handleAgentResponse);
//         } else {
//             onboardAgent(formData).then(handleAgentResponse);
//         }
//     };

const handleSubmit = () => {
    const agentData = {
        ...agentDetails,
        address: locationData?.formattedAddress,
        latitude: locationData?.lat,
        longitude: locationData?.lng,
        city: locationData?.city,
        state: locationData?.state,
        country: locationData?.country,
        removedDocServiceIds: removedServiceIds,
        newAcceptedServiceIds: newAcceptedServiceIds,
        agentId: updateAgent?.agentId,
    };

    const formData = new FormData();
    // If file exists, append it to FormData
    if (citizenServiceCenterLicense) {
        formData.append('citizenServiceCenterLicense', citizenServiceCenterLicense);
    }

    if (profilePicture) {
        formData.append('agentProfilePicture', profilePicture);
    }


    // Convert the agent data to JSON and append it as a Blob (set type to 'application/json')
    formData.append('infoDto', new Blob([JSON.stringify(agentData)], { type: 'application/json' }));

    // Do NOT set the Content-Type header explicitly - the browser will handle it
    if (updateAgent) {
        updateAgentMutation(formData).then(handleAgentResponse);
    } else {
        onboardAgent(formData).then(handleAgentResponse);
    }
};

    const handleAgentResponse = (res) => {
        if (res?.data?.status === 0) {
            message.success(`${updateAgent ? 'Updated' : 'Created'} agent successfully!`);
            navigate(ADMIN_DASHBOARD_BASE_PATH + AGENT_BASE_PATH);
        } else {
            message.error('Failed to save agent details!');
        }
    };

    return (
        <Content style={{ maxWidth: '1200px', margin: 'auto', padding: '0px 10px 10px 10px' }}>
             <Typography.Title level={2} style={{ textAlign: 'center' }}>{updateAgent ? 'Update' : 'Add'} Agent</Typography.Title>
        <Space direction="vertical" style={{ width: '100%' }}>
            <Steps current={currentStep}>
                <Step title="Basic Details" />
                <Step title="Verify Location" />
                <Step title="Uplode Documents" />
                <Step title="Choose Services" />
            </Steps>
            <Card>
                {currentStep === 0 && <BasicDetailsStep updateAgent={updateAgent} agentDetails={agentDetails} setAgentDetails={setAgentDetails} />}
                {currentStep === 1 && (
                    <VerifyLocationStep
                        address={address}
                        setAddress={setAddress}
                        locationData={locationData}
                        setLocationData={setLocationData}
                        isAddressVerified={isAddressVerified}
                        setIsAddressVerified={setIsAddressVerified}
                    />
                )}

                {currentStep === 2 && (
                    <AgentDocuments
                    citizenServiceCentreLicense={citizenServiceCenterLicense}
                    setCitizenServiceCentreLicense={setCitizenServiceCenterLicense}
                    agentEmail={updateAgent? updateAgent?.email : null}
                    profilePicture={profilePicture}
                    setProfilePicture={setProfilePicture}
                    />
                )}
                

                {currentStep === 3 && (
                    <ChooseServicesStep
                        suscribedServiceIds={suscribedServiceIds}
                        setSuscribedServiceIds={setSuscribedServiceIds}
                        removedServiceIds={removedServiceIds}
                        setRemovedServiceIds={setRemovedServiceIds}
                        newAcceptedServiceIds={newAcceptedServiceIds}
                        setNewAcceptedServiceIds={setNewAcceptedServiceIds}
                    />
                )}
                <Row justify="space-between">
                    {currentStep > 0 && <Button onClick={handleBack}>Back</Button>}
                    {currentStep < 3 ? (
                        <Button type="primary" onClick={handleNext}>Next</Button>
                    ) : (
                        <Button type="primary" onClick={handleSubmit} loading={onboardAgentRes.isLoading || updateAgentRes.isLoading}>
                            Submit
                        </Button>
                    )}
                </Row>
            </Card>
        </Space>
        </Content>
    );
};

export default AddUpdateAgent;


// import React, { useState } from 'react';
// import {
//     Form,
//     Input,
//     Button,
//     Card,
//     Typography,
//     Space,
//     Row,
//     Col,
//     Modal,
//     message,
//     Steps,
//     Dropdown,
//     Menu,
//     Spin,
// } from 'antd';
// import { CheckCircleFilled } from '@ant-design/icons';
// import MapComponent from '../../../components/MapComponent';
// import { useOnboardAgentMutation, useUpdateAgentMutation } from '../../../store/RTK_query/agent.api.query';
// import { useNavigate } from 'react-router-dom';
// import { ADMIN_DASHBOARD_BASE_PATH, AGENT_BASE_PATH, UPDATE_TYPE_AGENT } from '../../../comman/Constanst';
// import { useGetServicesQuery, useGetTermOfServiceMutation } from '../../../store/RTK_query/service.api.query';
// import { useUpdateRequestData } from '../../../store/UseUpdateRequestData';
// import './AddUpdateAgent.css'; // Importing the CSS file for additional styles
// import { Content } from 'antd/es/layout/layout';
// import DOMPurify from 'dompurify';

// const { Title } = Typography;
// const { Step } = Steps;

// const AddUpdateAgent = () => {
//     const [form] = Form.useForm();
//     const updateAgent = useUpdateRequestData(UPDATE_TYPE_AGENT) || null;
//     const [currentStep, setCurrentStep] = useState(0);
//     const [locationData, setLocationData] = useState(null);
//     const [isAddressVerified, setIsAddressVerified] = useState(false);
//     const navigate = useNavigate();
//     const services = useGetServicesQuery("ALL");
//     const [onboardAgent, onboardAgentRes] = useOnboardAgentMutation();
//     const [getSLA, SLARes] = useGetTermOfServiceMutation();
//     const [updateAgentMutation, updateAgentRes] = useUpdateAgentMutation();
//     const [acceptedServiceIds, setAcceptedServiceIds] = useState(() => {
//         return updateAgent ? updateAgent?.docsServices?.map(s => s.serviceId) : [];
//     });
//     const [removedServiceIds,setRemovedServiceIds] = useState([]);
//     const [newAcceptedServiceIds,setNewAcceptedServiceIds] = useState([]);
//     const [agentDetails, setAgentDetails] = useState({
//         firstName: updateAgent?.firstName || '',
//         lastName: updateAgent?.lastName || '',
//         email: updateAgent?.email || '',
//         contactNumber: updateAgent?.contactNumber || '',
//     });
//     const [address, setAddress] = useState(updateAgent ? updateAgent?.address : "");
//     const [searchTerm, setSearchTerm] = useState('');


//     const handleNext = () => {
//         if (currentStep === 0) {
//             form.validateFields()
//                 .then(() => {
//                     setCurrentStep(currentStep + 1);
//                 })
//                 .catch(info => {
//                     console.log('Validate Failed:', info);
//                 });
//         } else if (currentStep === 1) {
//             if (!isAddressVerified) {
//                 Modal.warning({
//                     title: 'Verify Address First',
//                     content: 'Please verify the address before proceeding.',
//                 });
//                 return;
//             }
//             setCurrentStep(currentStep + 1);
//         }
//     };

//     const handleBack = () => {
//         setCurrentStep(currentStep - 1);
//     };

//     const handleVerifyAddress = () => {
//         if (locationData && locationData.formattedAddress && locationData.lat && locationData.lng) {
//             setIsAddressVerified(true);
//             Modal.success({
//                 title: 'Address Verified',
//                 content: (
//                     <div>
//                         <p><b>Address :</b> {locationData?.formattedAddress}</p>
//                         <p><b>Latitude:</b> {locationData?.lat}</p>
//                         <p><b>Longitude:</b> {locationData?.lng}</p>
//                         <p><b>City:</b>{locationData?.city}<br /><b>State:</b>{locationData?.state}<br /><b>Country:</b>{locationData?.country}</p>
//                         <p>The address and location details are verified successfully. Please click "Next" to proceed.</p>
//                     </div>
//                 ),
//             });
//         } else {
//             setIsAddressVerified(false);
//             Modal.error({
//                 title: 'Verification Failed',
//                 content: 'Please enter a valid address and ensure the location is correct.',
//             });
//         }
//     };

//     const handleSubmit = () => {
//         const agentData = {
//             ...agentDetails,
//             address: locationData?.formattedAddress,
//             latitude: locationData?.lat,
//             longitude: locationData?.lng,
//             city: locationData?.city,
//             state: locationData?.state,
//             country: locationData?.country,
//             removedDocServiceIds: removedServiceIds,
//             newAcceptedServiceIds: newAcceptedServiceIds,
//             agentId: updateAgent?.agentId,
//         };

//         if (updateAgent) {
//             updateAgentMutation(agentData).then(res => {
//                 if (res?.data?.status === 0) {
//                     form.resetFields();
//                     setIsAddressVerified(false);
//                     message.success("Agent details updated successfully.");
//                     navigate(ADMIN_DASHBOARD_BASE_PATH + AGENT_BASE_PATH);
//                 } else {
//                     console.error(res);
//                     message.error("Agent details not updated");
//                 }
//             });
//         } else {
//             onboardAgent(agentData).then(res => {
//                 if (res?.data?.status === 0) {
//                     form.resetFields();
//                     setIsAddressVerified(false);
//                     message.success("Agent details saved successfully.");
//                     navigate(ADMIN_DASHBOARD_BASE_PATH + AGENT_BASE_PATH);
//                 } else {
//                     console.error(res);
//                     message.error("Agent details not saved");
//                 }
//             });
//         }
//     };

//     const handleAddressChange = (e) => {
//         setAddress(e.target.value);
//         setIsAddressVerified(false);
//     };

//     const handleLocationChange = (location) => {
//         setLocationData(location);
//     };

//     const handleServiceSearch = (value) => {
//         setSearchTerm(value);
//     };

//     const handleServiceSelect = (serviceId, serviceName) => {
//         // Show loading spinner while fetching SLA
//         const modal = Modal.info({
//             title: (
//                 <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                     <div style={{ marginBottom: '16px' }}>
//                         {serviceName + " (SLA)"}
//                     </div>
//                     <Button
//                         type="text"
//                         onClick={() => modal.destroy()}
//                         style={{ color: '#aaa' }}
//                     >
//                         &times;
//                     </Button>
//                 </div>
//             ),
//             content: (
//                 <div style={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                     <Spin spinning={true} size="large" style={{ marginBottom: '20px' }} />
//                     <div style={{ display: 'none' }}>
//                         {/* Placeholder for the actual content until it's loaded */}
//                     </div>
//                 </div>
//             ),
//             okText: 'Close',
//             width: '90%',
//             style: { maxWidth: '800px' },
//         });
    
//         getSLA(serviceId).then(res => {
//             if (res?.data?.status === 0) {
//                 const sanitizedHTML = DOMPurify.sanitize(res?.data?.response?.agreement);
//                 modal.update({
//                     content: (
//                         <div style={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column' }}>
//                             <div
//                                 style={{
//                                     overflowY: 'auto',
//                                     maxHeight: '60vh',
//                                     marginBottom: '20px',
//                                     padding: '10px',
//                                     border: '1px solid #f0f0f0',
//                                     borderRadius: '4px',
//                                     backgroundColor: '#ffffff'
//                                 }}
//                                 dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
//                             />
//                         </div>
//                     ),
//                     footer: (
//                         <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
//                             <Button
//                                 onClick={() => {
//                                     handleAcceptService(serviceId);
//                                     modal.destroy();
//                                 }}
//                             >
//                                 Accept Service Level Agreement
//                             </Button>
//                             <Button onClick={() => modal.destroy()}>
//                                 Close
//                             </Button>
//                         </div>
//                     ),
//                 });
//             }
//         }).catch(error => {
//             console.error("Error fetching SLA:", error);
//             message.error("Failed to load Service Level Agreement!");
//             modal.destroy();
//         });
//     };
    

//     const handleAcceptService = (serviceId) => {
//         if (!acceptedServiceIds.includes(serviceId)) {
//             setAcceptedServiceIds(prev => [...prev, serviceId]);
//             setNewAcceptedServiceIds((prevState) => [...prevState, serviceId]);
//             message.success("Service accepted!");
//         }
//         setSearchTerm(''); // Clear search input after accepting
//     };

//     const handleRemoveService = (serviceId) => {
//         setAcceptedServiceIds(prev => prev.filter(id => id !== serviceId));
//         setRemovedServiceIds(prev => [...prev,serviceId]);
//     };

//     const renderServiceDropdown = () => {
//         // Check if services are loaded and handle undefined case
//         const servicesData = services.data?.response || [];
//         // Show all services if the searchTerm is empty
//         const filteredServices = servicesData.filter(service =>
//             service.serviceName.toLowerCase().includes(searchTerm.toLowerCase())
//         );

//         // If no services match, display a message in the dropdown
//         const dropdownContent = filteredServices.length > 0 ? (
//             filteredServices.map(service => (
//                 <Menu.Item key={service.serviceId} onClick={() => handleServiceSelect(service.serviceId, service?.serviceName)}>
//                     <Row justify="space-between" align="middle" style={{ width: '100%' }} >
//                         <Col>
//                             <strong>{service.serviceName}</strong>
//                         </Col>
//                     </Row>
//                 </Menu.Item>
//             ))
//         ) : (
//             <Menu.Item disabled>No services found</Menu.Item>
//         );

//         return (
//             <Dropdown
//                 overlay={
//                     <Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
//                         {dropdownContent}
//                     </Menu>
//                 }
//                 trigger={['click']}
//             >
//                 <Input.Search
//                     placeholder="Search for services"
//                     value={searchTerm}
//                     onChange={(e) => handleServiceSearch(e.target.value)}
//                     allowClear
//                     style={{ width: '100%', marginBottom: '20px' }}
//                 />
//             </Dropdown>
//         );
//     };


//     const steps = [
//         {
//             title: 'Basic Details',
//             content: (
//                 <Form
//                     form={form}
//                     layout="vertical"
//                     autoComplete="off"
//                     initialValues={agentDetails}
//                 >
//                     <Row gutter={16}>
//                         <Col xs={24} sm={12}>
//                             <Form.Item
//                                 label="First Name"
//                                 name="firstName"
//                                 rules={[{ required: true, message: 'Please input the first name!' }]}
//                             >
//                                 <Input placeholder="Enter first name" onChange={e => setAgentDetails({ ...agentDetails, firstName: e.target.value })} />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} sm={12}>
//                             <Form.Item
//                                 label="Last Name"
//                                 name="lastName"
//                                 rules={[{ required: true, message: 'Please input the last name!' }]}
//                             >
//                                 <Input placeholder="Enter last name" onChange={e => setAgentDetails({ ...agentDetails, lastName: e.target.value })} />
//                             </Form.Item>
//                         </Col>
//                     </Row>
//                     <Row gutter={16}>
//                         <Col xs={24} sm={12}>
//                             <Form.Item
//                                 label="Email Address"
//                                 name="email"
//                                 rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
//                             >
//                                 <Input placeholder="Enter email address" onChange={e => setAgentDetails({ ...agentDetails, email: e.target.value })} />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} sm={12}>
//                             <Form.Item
//                                 label="Contact Number"
//                                 name="contactNumber"
//                                 rules={[{ required: true, message: 'Please input contact number!' }]}
//                             >
//                                 <Input placeholder="Enter contact number" onChange={e => setAgentDetails({ ...agentDetails, contactNumber: e.target.value })} />
//                             </Form.Item>
//                         </Col>
//                     </Row>
//                 </Form>
//             ),
//         },
//         {
//             title: 'Verify Location',
//             content: (
//                 <>
//                     {locationData?.formattedAddress}
//                     <Form.Item
//                         label="Address"
//                         name="addressInput"
//                         rules={[{ required: true, message: 'Please input an address!' }]}
//                     >
//                         <Input placeholder="Enter address" onChange={handleAddressChange} />
//                     </Form.Item>
//                     <MapComponent address={address} onLocationChange={handleLocationChange} />
//                     {!isAddressVerified && (
//                         <Button
//                             type="primary"
//                             block
//                             style={{ marginTop: '20px' }}
//                             onClick={handleVerifyAddress}
//                         >
//                             Verify Address
//                         </Button>
//                     )}
//                 </>
//             ),
//         },
//         {
//             title: 'Choose Services',
//             content: (
//                 <>
//                     {renderServiceDropdown()}

//                     {acceptedServiceIds.length > 0 && (
//                         <div style={{ marginTop: 20 }}>
//                             <strong>Accepted Services:</strong>
//                             <div style={{ overflowX: 'auto' }}> {/* Makes it scrollable on mobile */}
//                                 <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: 16 }}>
//                                     <thead>
//                                         <tr>
//                                             <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Service Name</th>
//                                             <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Terms</th>
//                                             <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Remove</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {acceptedServiceIds.map(serviceId => {
//                                             const service = services.data?.response?.find(s => s.serviceId === serviceId);
//                                             return (
//                                                 <tr key={serviceId}>
//                                                     <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
//                                                         {service?.serviceName || 'Unknown Service'}
//                                                     </td>
//                                                     <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
//                                                         <CheckCircleFilled style={{ color: 'green', fontSize: '15px' }} /><Button type="link" onClick={() => handleServiceSelect(service.serviceId, service?.serviceName)}>View Terms</Button>
//                                                     </td>

//                                                     <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
//                                                         <Button type="primary" danger onClick={() => handleRemoveService(serviceId)}>Remove</Button>
//                                                     </td>
//                                                 </tr>
//                                             );
//                                         })}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     )}
//                 </>
//             ),
//         },
//     ];

//     return (
//         <Content style={{ maxWidth: '1200px', margin: 'auto', padding: '0px 10px 10px 10px' }}>
//             <Title level={2} style={{ textAlign: 'center' }}>{updateAgent ? 'Update' : 'Add'} Agent</Title>
//             <Steps current={currentStep}>
//                 {steps.map(step => (
//                     <Step key={step.title} title={step.title} />
//                 ))}
//             </Steps>
//             <Space direction="vertical" size="large" style={{ width: '100%', marginTop: '20px' }}>
//                 <Card title={steps[currentStep].title} bordered hoverable>
//                     {steps[currentStep].content}
//                     <Row justify="space-between" style={{ marginTop: '20px' }}>
//                         {currentStep > 0 && (
//                             <Col>
//                                 <Button type="default" onClick={handleBack}>Back</Button>
//                             </Col>
//                         )}
//                         <Col>
//                             {currentStep < steps.length - 1 ? (
//                                 <Button type="primary" onClick={handleNext}>
//                                     Next
//                                 </Button>
//                             ) : (
//                                 <Button
//                                     type="primary"
//                                     onClick={handleSubmit}
//                                     loading={onboardAgentRes?.isLoading || updateAgentRes?.isLoading}
//                                 >
//                                     Submit Agent Details
//                                 </Button>
//                             )}
//                         </Col>
//                     </Row>
//                 </Card>
//             </Space>
//             {/* <TermsOfServiceModal visible={isTermModalVisible} onClose={closeTermModal} term={term} /> */}

//         </Content>
//     );
// };

// export default AddUpdateAgent;
