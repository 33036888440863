// src/PaymentPage.js
import React, { useState } from 'react';
import { Form, Input, Button, Typography, Card, Slider } from 'antd';

const { Title } = Typography;

const PaymentPage = () => {
    const [storage, setStorage] = useState(100);  // Default storage in GB
    const [totalCost, setTotalCost] = useState(100);  // Default cost (₹100 for 100GB)

    // Handle slider change
    const onStorageChange = (value) => {
        setStorage(value);
        setTotalCost(value / 100 * 100);  // ₹100 for each 100GB
    };

    const onFinish = (values) => {
        console.log('Payment details:', values);
        console.log(`Storage selected: ${storage}GB, Total cost: ₹${totalCost}`);
        // Handle payment processing here
    };

    return (
        <div style={{ padding: '50px', backgroundColor: '#f0f2f5' }}>
            <Card style={{ maxWidth: '500px', margin: '0 auto', borderRadius: '10px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                <Title level={3} style={{ textAlign: 'center', color: '#FF0043' }}>Cloud Storage Payment</Title>
                
                {/* Display the dynamic pricing */}
                <p style={{ textAlign: 'center', color: '#555' }}>
                    <strong>{storage}GB</strong> selected — Monthly Charge: <strong>₹{totalCost}</strong>
                </p>
                
                {/* Slider to select storage */}
                <Form.Item label="Select Storage (GB)" style={{ marginBottom: '20px' }}>
                    <Slider
                        min={100}
                        max={1000}
                        step={100}
                        value={storage}
                        onChange={onStorageChange}
                        tooltipVisible
                        marks={{ 100: '100GB', 500: '500GB', 1000: '1TB' }}
                    />
                </Form.Item>

                <Form
                    name="payment"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input placeholder="Enter your name" />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                    >
                        <Input placeholder="Enter your email" />
                    </Form.Item>
                    <Form.Item
                        label="Card Number"
                        name="cardNumber"
                        rules={[{ required: true, message: 'Please input your card number!' }]}
                    >
                        <Input placeholder="Enter your card number" />
                    </Form.Item>
                    <Form.Item
                        label="Expiry Date"
                        name="expiryDate"
                        rules={[{ required: true, message: 'Please input the expiry date!' }]}
                    >
                        <Input placeholder="MM/YY" />
                    </Form.Item>
                    <Form.Item
                        label="CVV"
                        name="cvv"
                        rules={[{ required: true, message: 'Please input your CVV!' }]}
                    >
                        <Input.Password placeholder="Enter your CVV" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#FF0043', borderColor: '#FF0043' }}>
                            Pay ₹{totalCost} Now
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default PaymentPage;
