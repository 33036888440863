//user roles
export const ADMIN = "ROLE_ADMIN"
export const USER = "ROLE_USER"
export const AGENT = "ROLE_AGENT"

//updatetion
export const UPDATE_TYPE_AGENT = "AGENT"
export const UPDATE_TYPE_SERVICE = "SERVICE"

//paths
export const USER_DASHBOARD_BASE_PATH="/user_dashboard"
export const ADMIN_DASHBOARD_BASE_PATH="/admin_dashboard"
export const AGENT_DASHBOARD_BASE_PATH="/agent_dashboard"


export const AGENT_TYPE_BASE_PATH="/agent_type"
export const AGENT_ADD_UPDATE_BASE_PATH="/agent_add_update"
export const AGENT_BASE_PATH = "/agents";
export const SERVICE_ADD_UPDATE_BASE_PATH="/service_add_update"
export const SERVICE_BASE_PATH="/services"
export const ORDERS_BASE_PATH="/orders"
export const PLACE_ORDER_1_BASE_PATH = "/place_order"
export const SERVICE_DETAILS_BASE_PATH = "/service_details/active"
export const INVOICE_BASE_PATH="/invoice"
export const AGENT_PROFILE_BASE_PATH = "/agent_profile"
export const AGENT_FORGOTE_PASSWORD_PATH="/forgot_password/agent"

//env 
export const BASE_URL =process.env.REACT_APP_BASE_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const HOST = process.env.REACT_APP_HOST;
export const ORIGIN = process.env.REACT_APP_ORIGIN;
export const RAISE_TICKET_URL = process.env.REACT_APP_NUVIO_CRM_RAISE_TICKET_URL;
