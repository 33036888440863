import React, { useMemo, useState } from 'react';
import { Button, Checkbox, Divider, Tabs } from 'antd';
import AgentOrders from './AgentOrders';
import { useGetAgentOrdersQuery } from '../../../store/RTK_query/order.api.query';


const OrderTabs = () => {

  const [status,setStatus] = useState("NEW");
  let agentOrders = [];
  const agentOrderRes = useGetAgentOrdersQuery({ page: 0, limit: 20 , status});

  if (agentOrderRes?.isSuccess) {
    // alert(JSON.stringify(agentOrderRes?.data))
    agentOrders = agentOrderRes?.data?.response?.content;
  }

  const handleOrderTabChange = (key) => {
    setStatus(key);
  };

  const items = [
    {
      key: 'NEW',
      label: 'New Orders',
      children:
        <>
          <AgentOrders agentOrders={agentOrders} />
        </>,
    },
    {
      key: 'PROCESSING',
      label: 'Processing Orders',
      children: <AgentOrders agentOrders={agentOrders} />,
    },
    {
      key: 'ASSIGNE_TO_DELIVERY',
      label: 'Assign to delivery',
      children: <AgentOrders agentOrders={agentOrders} />,
    },

    {
      key: 'DELIVERING',
      label: 'Delivering Orders',
      children: <AgentOrders agentOrders={agentOrders} />,
    },

    {
      key: 'DELIVERED',
      label: 'Delivered Orders',
      children: <AgentOrders agentOrders={agentOrders} />,
    },

    {
      key: 'CANCELLED_BY_AGENT',
      label: 'Cancel By Agent',
      children: <AgentOrders agentOrders={agentOrders} />,
    },
    {
      key: 'CANCELLED_BY_USER',
      label: 'Cancel By User',
      children: <AgentOrders agentOrders={agentOrders} />,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="NEW" items={items} onChange={handleOrderTabChange} />
    </>
  );
};
export default OrderTabs;