import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../comman/Constanst'
import { getToken } from '../../comman/Actions'

export const userApi = createApi({
    reducerPath : 'userApi',
    baseQuery : fetchBaseQuery({baseUrl:BASE_URL}),
    tagTypes : ['userDetails'],
    endpoints : (builder) => ({
        login : builder.mutation({
            query :(data)=>({
                url: `/login`,
                method: 'post',
                body:data,
               
            }),
            invalidatesTags:['userDetails']
        }),
        Register : builder.mutation({
            query :(data)=>({
                url: `/register`,
                method: 'post',
                body:data,
               
            }),
            invalidatesTags:['userDetails']
        }),
        getLoginUser: builder.query({
            query: () => ({
                url: `/user/getUserDetails/me`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            providesTags: ['userDetails']
        }),

        validateToken: builder.mutation({
            query: () => ({
                url: `/validateToken`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
        }),

        saveGuestCodOrder : builder.mutation({
            query :(data)=>({
                url: `/placeCodOrder`,
                method: 'post',
                body:data,
            }),
            invalidatesTags:['userDetails']
        }),

        trackOrder : builder.mutation({
            query :(data)=>({
                url: `/trackOrder?email=${data?.email}&orderId=${data?.orderId}`,
                method: 'POST',
            }),
        }),

        forgotAgentPassword : builder.mutation({
            query :(email)=>({
                url: `/forgotAgentPassword?email=${email}`,
                method: 'Post',
            }),
        }),

        resetAgentPassword : builder.mutation({
            query :(data)=>({
                url: `/resetAgentPassword?password=${data?.password}&token=${data?.token}`,
                method: 'Post',
            }),
        }),
        
    })
})

export const {useLoginMutation, useRegisterMutation, useGetLoginUserQuery,useTrackOrderMutation,useForgotAgentPasswordMutation, useResetAgentPasswordMutation, useSaveGuestCodOrderMutation, useValidateTokenMutation} = userApi