import React from 'react';
import { Form, Input, Row, Col } from 'antd';

const BasicDetailsStep = ({ agentDetails, setAgentDetails }) => {
    const handleInputChange = (e) => {
        setAgentDetails({ ...agentDetails, [e.target.name]: e.target.value });
    };

    return (
        <Form
            layout="vertical"
            initialValues={agentDetails}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="First Name" required>
                        <Input
                            name="firstName"
                            value={agentDetails.firstName}
                            onChange={handleInputChange}
                            placeholder="Enter first name"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Last Name" required>
                        <Input
                            name="lastName"
                            value={agentDetails.lastName}
                            onChange={handleInputChange}
                            placeholder="Enter last name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Email" required>
                        <Input
                            name="email"
                            value={agentDetails.email}
                            onChange={handleInputChange}
                            placeholder="Enter email"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Contact Number" required>
                        <Input
                            name="contactNumber"
                            value={agentDetails.contactNumber}
                            onChange={handleInputChange}
                            placeholder="Enter contact number"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default BasicDetailsStep;
