// src/utils/messageUtil.js
import { notification } from 'antd';

const notify = (type, message, description, duration = 3, placement = 'topRight') => {
  notification[type]({
    message: message,
    description: description,
    duration: duration,
    placement: placement,
  });
};

const MessageUtil = {
  success: (config) => notify('success', config.message, config.description, config.duration, config.placement),
  error: (config) => notify('error', config.message, config.description, config.duration, config.placement),
  info: (config) => notify('info', config.message, config.description, config.duration, config.placement),
  warning: (config) => notify('warning', config.message, config.description, config.duration, config.placement),
};

export default MessageUtil;
