import { Button, Card, Col, Divider, Row, Space, Typography, message } from 'antd'
import React from 'react'
import { useGetAgentOrdersQuery, useUpdateOrderDocServiceStatusMutation } from '../../../store/RTK_query/order.api.query';


const { Title, Text } = Typography;

export default function AgentOrders({ agentOrders }) {
  const [updateOrderDocService] = useUpdateOrderDocServiceStatusMutation();

  const changeOrderstaus = (orderDocServiceId, status) => {
    updateOrderDocService({ orderDocServiceId, status }).then(res => {
      if (res?.data?.status === 0) {
        message.success("order status changed.")
      } else {
        message.error("status not changed!")
      }
    })
  }
  return (
    <div style={{ padding: '0', background: '#f0f2f5' }}>

      <Space direction="vertical" style={{ width: '100%' }}>

        <Row gutter={5}>
          {agentOrders.length > 0 ? (
            agentOrders?.map((order) => (
              (order?.orderRef?.billingStatus === "PAID" || order?.orderRef?.paymentType==="CASH_ON_DELIVERY" ) &&
              <Col xs={24} sm={24} md={12} lg={8} xl={8} key={order?.orderRef?.orderRefId}>
                <Card
                  style={{ marginTop: "10px" }}
                >
                  <h2>{order?.orderDocsService?.serviceName} | {`OID: ${order?.orderRef?.orderRefId}`}</h2>
                  <Text strong>Buyer Name:</Text> {order?.orderRef?.userFullName}<br />
                  <Text strong>Buyer Contact:</Text> {order?.orderRef?.contactNumber}<br />
                  <Text strong>Payment Mode:</Text> {order?.orderRef?.paymentType}<br />
                  <Text strong>Price: ₹</Text> {order?.orderDocsService?.serviceCharge} {order?.orderRef?.billingStatus === "UNPAID" ? <small style={{ color: "red" }}>unpaid</small> : <>{order?.orderRef?.billingStatus === "PAID" && <small style={{ color: "green" }}>paid</small>}</>} <br />
                  <Text strong>Placed Date:</Text> {new Date(order?.createdDtTime).toLocaleString()}<br />
                  <Text strong>Address:</Text> {order?.orderRef?.address + ` (lat : ${order?.orderRef?.latitude}, lng : ${order?.orderRef?.longitude})`}<br />
                  <Divider >Status</Divider>
                  <div>
                    <div>
                      Current Status : <b>{order?.orderDocsService?.status}</b>
                    </div>
                    <div>
                      Change Status :

                      {
                        order?.orderDocsService?.status === "NEW" &&
                        <Button style={{ margin: "5px" }} onClick={() => { changeOrderstaus(order?.orderDocsService?.orderServiceId, "PROCESSING") }} size='small'>Processing</Button>
                      }
                      {
                        (order?.orderDocsService?.status === "PROCESSING" || order?.orderDocsService?.status === "ASSIGNE_TO_DELIVERY") &&
                        <Button style={{ margin: "5px" }} onClick={() => { changeOrderstaus(order?.orderDocsService?.orderServiceId, "ASSIGNE_TO_DELIVERY") }} size='small'>Assign To Delivery</Button>
                      }
                      {/* {
                        order?.orderDocsService?.status === "DELIVERING" &&
                        <Button style={{ margin: "5px" }} onClick={() => { changeOrderstaus(order?.orderDocsService?.orderServiceId, "DELIVERED") }} size='small'>Delivered</Button>
                      } */}
                      {
                        (order?.orderDocsService?.status !== "DELIVERING" && order?.orderDocsService?.status !== "DELIVERED" && order?.orderDocsService?.status !== "ASSIGNE_TO_DELIVERY" && order?.orderDocsService?.status !== "CANCELLED_BY_AGENT" && order?.orderDocsService?.status !== "CANCELLED_BY_USER") &&
                        <Button style={{ margin: "5px" }} danger onClick={() => { changeOrderstaus(order?.orderDocsService?.orderServiceId, "CANCELLED_BY_AGENT") }} size='small' >Cancel</Button>
                      }

                    </div>
                  </div>
                  <br />
                </Card>
              </Col>

            ))
          ) : (
            <Col span={24}>
              <Card style={{ width: '100%', textAlign: 'center' }}>
                <Title level={4}>No orders found</Title>
              </Card>
            </Col>
          )}
        </Row>
      </Space>
    </div>
  )
}
