import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, canonical, robots, schemaMarkup }) => (
  <Helmet>
    {/* Dynamic Meta Tags */}
    <title>{`${title} - ${process.env.REACT_APP_WEBSITE_NAME}`}</title>
    <meta name="description" content={description} />
    {keywords && <meta name="keywords" content={keywords} />}
    {canonical && <link rel="canonical" href={canonical} />}
    {robots && <meta name="robots" content={robots} />}

    {/* Schema Markup for structured data (optional) */}
    {schemaMarkup && (
      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
    )}
  </Helmet>
);

export default SEO;
