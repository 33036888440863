import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_KEY, BASE_URL, HOST, ORIGIN } from '../../comman/Constanst'
import { getToken } from '../../comman/Actions';

export const agentTypeApi = createApi({
    reducerPath : 'agentTypeApi',
    baseQuery : fetchBaseQuery({
        baseUrl:BASE_URL,
        prepareHeaders: (headers) => {
            const  token  = getToken(); 
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
            headers.set('apikey', API_KEY);
            headers.set('host',HOST);
            headers.set('origin',ORIGIN);
            return headers;
          },
    }),
    tagTypes : ['agentType'],
    endpoints : (builder) => ({
        getAgentType : builder.query({
            query :()=>({
                url: `/admin/getAgentTypes`,
                method: 'get'
            }),
            providesTags:['agentType']
        }),
        saveAgentType : builder.mutation({
            query :(details)=>({
                url: `/admin/saveAgentType`,
                method: 'post',
                body:details
            }),
            invalidatesTags:['agentType']
        }),
        
    })
})

export const {useGetAgentTypeQuery, useSaveAgentTypeMutation } = agentTypeApi